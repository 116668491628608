import React, { cloneElement, useMemo } from 'react'
import { useSteps } from 'mdx-deck'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

const ListItem = styled(motion.li)``

export function AppearingList({ children, as: htmlParent = "ul" }) {
  const totalSteps = useMemo(() => determineSteps(children), [children])
  const step = useSteps(totalSteps)
  const As = motion[htmlParent] || motion.ul

  const renderChild = (item, index) => {
    return step > index && cloneElement(item, {
      transitionPosition: true,
      step: step - index,
      key: index,
      initial: { opacity: 0, x: 100, ...(item.props.initial || {}) },
      animate: { opacity: 1, x: 0, ...(item.props.animate || {}) }
    })
  }

  return (
    <div style={{ padding: 0 }}>
      <As transitionPosition>
        {React.Children.map(children, renderChild)}
      </As>
    </div>
  )
}

function NestedList({ step, children, as: htmlParent = "ul" }) {

  const As = motion[htmlParent] || motion.ul
  const renderChild = (item, index) => {
    return step > index && cloneElement(item, {
      key: index,
      step: step - index,
      transitionPosition: true,
      initial: { opacity: 0, x: 100 },
      animate: { opacity: 1, x: 0 }
    })
  }

  return (
    <As transitionPosition>
      {React.Children.map(children, renderChild)}
    </As>
  )
}

function determineSteps(children) {
  let count = 0;

  React.Children.forEach(children, (child) => {
    if (child.type === ListItem || child.type.displayName === "ListItem") {
      count += 1;
    } else {
      if (React.Children.count(child.props.children) > 0) {
        count += determineSteps(child.props.children)
      }
    }
  })

  return count
}

AppearingList.Item = ListItem
AppearingList.Nested = NestedList
