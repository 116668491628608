// @ts-check

import React, { useState, useCallback, useEffect } from 'react'

import { useCycle } from 'framer-motion'

export function Demo({ automated = true }) {
  const [autoState, nextAutoState] = useCycle([false, 2500], [true, 2500], [false, 1500], [true, 5000])
  const [manualState, setManualActive] = useState(false)
  const doToggle = useCallback(() => setManualActive((prev) => !prev), [setManualActive])

  useEffect(() => {
    if (!automated) {
      return
    }

    // @ts-ignore
    const timer = setTimeout(nextAutoState, autoState[1])
    return () => clearTimeout(timer)

  }, [autoState, automated, nextAutoState])

  const active = automated ? autoState[0] : manualState

  return (
    <section style={{ padding: 16, width: 320, height: 190 }}>
      <nav>
        <button
          id="navToggler"
          style={{
            display: 'inline-block',
            padding: 8,
            fontSize: 48,
            textDecoration: 'none',
            background: 'none',
            border: '1px solid gray',
            marginBottom: 8
          }}
          onClick={doToggle}
          >
          <span role="img" aria-label="McToggle">🍔</span>
        </button>
        <div style={{
          fontSize: 32,
          padding: 16,
          background: '#eee',
          display: active ? 'block' : 'none'
        }}>
          Navigation Menu
        </div>
      </nav>
    </section>
  )
}
