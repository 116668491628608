"use strict";

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.is-array");

require("core-js/modules/es6.array.fill");

require("core-js/modules/es6.array.for-each");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.map");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.string.trim");

require("core-js/modules/es6.array.index-of");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.map");

require("core-js/modules/es6.function.bind");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.date.to-string");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.reflect.construct");

require("core-js/modules/es6.object.set-prototype-of");

require("core-js/modules/es6.object.create");

require("core-js/modules/es7.string.trim-right");

function t(t) {
  return t && "object" == typeof t && "default" in t ? t["default"] : t;
}

var n = require("diff"),
    r = t(require("prismjs")),
    e = t(require("array.prototype.flat"));

function o(t) {
  return t && t.trimEnd().concat("\n");
}

function i(t, n) {
  t.prototype = Object.create(n.prototype), t.prototype.constructor = t, t.__proto__ = n;
}

function u(t) {
  return (u = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
    return t.__proto__ || Object.getPrototypeOf(t);
  })(t);
}

function c(t, n) {
  return (c = Object.setPrototypeOf || function (t, n) {
    return t.__proto__ = n, t;
  })(t, n);
}

function f(t, n, r) {
  return (f = function () {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;

    try {
      return Date.prototype.toString.call(Reflect.construct(Date, [], function () {})), !0;
    } catch (t) {
      return !1;
    }
  }() ? Reflect.construct : function (t, n, r) {
    var e = [null];
    e.push.apply(e, n);
    var o = new (Function.bind.apply(t, e))();
    return r && c(o, r.prototype), o;
  }).apply(null, arguments);
}

function a(t) {
  var n = "function" == typeof Map ? new Map() : void 0;
  return (a = function a(t) {
    if (null === t || -1 === Function.toString.call(t).indexOf("[native code]")) return t;
    if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");

    if (void 0 !== n) {
      if (n.has(t)) return n.get(t);
      n.set(t, r);
    }

    function r() {
      return f(t, arguments, u(this).constructor);
    }

    return r.prototype = Object.create(t.prototype, {
      constructor: {
        value: r,
        enumerable: !1,
        writable: !0,
        configurable: !0
      }
    }), c(r, t);
  })(t);
}

function s(t) {
  if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return t;
}

String.prototype.trimEnd = String.prototype.trimEnd || function () {
  if (String.prototype.trimRight) return this.trimRight();
  var t = this.trim(),
      n = this.indexOf(t);
  return this.slice(n, this.length);
};

var p = /\r\n|\r|\n/,
    h = function (t) {
  function n(r) {
    var e;
    return (e = t.call(this, 'Missing syntax highlighting for language "' + r + '"') || this).lang = r, Object.setPrototypeOf(s(e), (this instanceof n ? this.constructor : void 0).prototype), e;
  }

  return i(n, t), n;
}(a(Error));

function l(t) {
  var n = t.match(/(\d+)\[(.+)\]/);

  if (n) {
    var r = n[1],
        o = n[2].split(",").map(y);
    return [[Number(r) - 1, e(o).map(function (t) {
      return t - 1;
    })]];
  }

  return y(t).map(function (t) {
    return [t - 1, !0];
  });
}

function y(t) {
  var n = t.split(":"),
      r = n[0],
      e = n[1];
  if (!v(r)) throw new g(r);
  var o = Number(r);
  if (o < 1) throw new d();

  if (e) {
    if (!v(e)) throw new g(e);

    for (var i = [], u = o; u <= +e; u++) {
      i.push(u);
    }

    return i;
  }

  return [o];
}

function v(t) {
  t = t.toString();
  var n = Math.abs(t),
      r = parseInt(t, 10);
  return !isNaN(n) && r === n && n.toString() === t;
}

var d = function (t) {
  function n() {
    var r;
    return r = t.call(this, "Invalid line or column number in focus string") || this, Object.setPrototypeOf(s(r), (this instanceof n ? this.constructor : void 0).prototype), r;
  }

  return i(n, t), n;
}(a(Error)),
    g = function (t) {
  function n(r) {
    var e;
    return (e = t.call(this, 'Invalid number "' + r + '" in focus string') || this).number = r, Object.setPrototypeOf(s(e), (this instanceof n ? this.constructor : void 0).prototype), e;
  }

  return i(n, t), n;
}(a(Error));

function m(t) {
  var n = t.split(/\r\n|\r|\n/),
      r = 0;
  return n.forEach(function (t, e) {
    n[r].length < t.length && (r = e);
  }), r;
}

exports.parseSteps = function (t) {
  if (0 === t.length) return {
    tokens: [],
    types: [],
    steps: []
  };
  var i = t[0].lang;
  if (!i) throw new Error("Missing code language");

  var u = function (t) {
    if ("diff" === t[0].lang) return t.map(function (t) {
      return t.code;
    });
    var r = "";
    return t.map(function (t) {
      var e = t.code,
          o = "diff" === t.lang ? n.applyPatch(r, e) : e;
      return r = o, o;
    });
  }(t),
      c = function (t) {
    var r = [],
        e = [];
    return t.forEach(function (i, u) {
      r.push(function (t, r, e, i) {
        void 0 === r && (r = []), void 0 === e && (e = ""), void 0 === i && (i = "");

        var u = function (t, r) {
          var i = n.diffLines(o(e), o(r)),
              u = 0,
              c = [];
          return i.forEach(function (t) {
            var n = t.count,
                r = void 0 === n ? 0 : n,
                e = t.removed,
                o = t.added;
            e && c.push({
              op: "-",
              count: r,
              index: u
            }), o && c.push({
              op: "+",
              count: r,
              index: u
            }), e || (u += r);
          }), c;
        }(0, i),
            c = r.slice(0);

        return u.forEach(function (n) {
          var r = n.count,
              e = n.index;
          if ("-" === n.op) c.splice(e, r);else {
            var o = function (t, n, r) {
              void 0 === n && (n = 0);
              var e = t.indexOf(n),
                  o = n || 0,
                  i = t[e + 1] || 1,
                  u = Array(r).fill(0).map(function (t, n) {
                return o + (i - o) * (n + 1) / (r + 1);
              });
              return t.splice.apply(t, [e + 1, 0].concat(u)), u;
            }(t, c[e - 1], r);

            c.splice.apply(c, [e, 0].concat(o));
          }
        }), c;
      }(e, r[u - 1], t[u - 1], t[u]));
    }), {
      lineIds: e,
      steps: r
    };
  }(u),
      f = c.lineIds,
      a = [],
      s = [],
      y = [];

  return c.steps.forEach(function (n, o) {
    var c = u[o],
        v = function (t, n) {
      if (!r.languages[n]) throw new h(n);

      var e = function t(n) {
        var r = [];
        return n.forEach(function (n) {
          var e = n.type,
              o = n.content;
          Array.isArray(o) ? r.push.apply(r, t(o)) : r.push({
            type: e,
            content: o
          });
        }), r;
      }(function t(n, r) {
        return void 0 === r && (r = "plain"), n.map(function (n) {
          return function n(r, e) {
            return void 0 === e && (e = "plain"), "string" == typeof r ? {
              type: e,
              content: r
            } : Array.isArray(r.content) ? {
              type: r.type,
              content: t(r.content, r.type)
            } : n(r.content, r.type);
          }(n, r);
        });
      }(r.tokenize(t, r.languages[n]))),
          o = [],
          i = [],
          u = [],
          c = [i],
          f = [u];

      return e.forEach(function (t) {
        var n = t.content.split(p),
            r = n.shift();
        void 0 !== r && "" !== r && (o.push({
          type: t.type,
          content: r
        }), i.push(r), u.push(t.type)), n.forEach(function (n) {
          o = [], u = [], c.push(i = []), f.push(u), "" !== n && (o.push({
            type: t.type,
            content: n
          }), i.push(n), u.push(t.type));
        });
      }), {
        tokens: c,
        types: f
      };
    }(c, i),
        d = v.tokens,
        g = v.types,
        b = [];

    n.forEach(function (t, n) {
      var r = f.indexOf(t);
      a[r] = d[n], s[r] = g[n], b.push(r);
    });
    var E = t[o].focus,
        O = y[o - 1] ? y[o - 1].lines : [],
        w = E ? function (t) {
      if (!t) throw new Error("Focus cannot be empty");
      if ("none" === t) return {};

      try {
        var n = t.split(/,(?![^\[]*\])/g).map(l);
        return function (t) {
          for (var n = {}, r = -1, e = null == t ? 0 : t.length; ++r < e;) {
            var o = t[r];
            n[o[0]] = o[1];
          }

          return n;
        }(e(n));
      } catch (t) {
        throw t;
      }
    }(E) : function (t, n) {
      var r = {};
      return n.forEach(function (n, e) {
        t.includes(n) || (r[e] = !0);
      }), 0 === Object.keys(r).length && n.forEach(function (t, n) {
        r[n] = !0;
      }), r;
    }(O, b),
        j = "none" === E ? {
      focusCenter: Math.round(b.length) / 2,
      focusCount: b.length
    } : function (t) {
      var n = Object.keys(t).map(function (t) {
        return +t;
      }),
          r = Math.min.apply(Math, n),
          e = Math.max.apply(Math, n);
      return {
        focusCenter: (r + e + 1) / 2,
        focusCount: e - r + 1
      };
    }(w);
    y.push({
      lines: b,
      focus: w,
      focusCenter: j.focusCenter,
      focusCount: j.focusCount,
      longestLineIndex: m(c),
      title: t[o].title,
      subtitle: t[o].subtitle
    });
  }), y.forEach(function (t) {
    var n,
        r = t.lines;
    (n = t.focus, Object.keys(n).map(function (t) {
      return [t, n[t]];
    })).forEach(function (t) {
      var n = t[0];

      if (Array.isArray(t[1])) {
        var e = r[n],
            o = function (t, n) {
          var r = [],
              o = [];
          return a[e].forEach(function (n, e) {
            var i = t[e];
            Array.from(n).forEach(function (t) {
              o.push(t), r.push(i);
            });
          }), [r, o];
        }(s[e]),
            i = o[1];

        s[e] = o[0], a[e] = i;
      }
    });
  }), a.forEach(function (t, n) {
    0 === t.length && (t.push("​"), s[n].push("plain"));
  }), {
    tokens: a,
    types: s,
    steps: y
  };
};