import React from 'react'
import { motion } from 'framer-motion'
import { navigate } from '@reach/router'
import { useDeck } from 'mdx-deck'

export const SlideButton = ({ slide, children, step = 0 }) => {
  const context = useDeck()

  return (<motion.button
    style={{ background: '#333', color: 'white', padding: 16, margin: '5%', borderRadius: '5%' }}
    onClick={() => gotoSlide({ ...context, index: slide(context.index) })}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
  >{children}</motion.button>)
}

const gotoSlide = ({ slug, length, index }) => {
  const n = index
  if (n >= length) return
  navigate([slug, n].join('/'))
}
