var warning = function warning() {};

var invariant = function invariant() {};

if (process.env.NODE_ENV !== 'production') {
  warning = function warning(check, message) {
    if (!check && typeof console !== 'undefined') {
      console.warn(message);
    }
  };

  invariant = function invariant(check, message) {
    if (!check) {
      throw new Error(message);
    }
  };
}

export { invariant, warning };