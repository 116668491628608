// @ts-check

import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'

export function TestRunner({ outcome, heading, message, runtime = 1500 }) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, runtime)
    return () => {
      clearTimeout(timer)
    }
  }, [runtime])


  return (
    <motion.section style={{ padding: 16, position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} positionTransition>
      <AnimatePresence>
        {!isLoading && <motion.h2 positionTransition key="done" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ margin: 0 }}>{outcome} {heading}</motion.h2>}
        {!isLoading && <motion.p positionTransition initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ margin: 0 }}>{message}</motion.p>}
      </AnimatePresence>
      <AnimatePresence>
        {isLoading &&
          <motion.div key="loading" initial={{ opacity: 0, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ margin: 0 }}>
            <h2>Running tests...</h2>
          </motion.div>
        }
      </AnimatePresence>
    </motion.section>
  )
}
