// @ts-check

import React, { useMemo, useEffect, useState } from 'react'
import { CodeSurfer } from '@code-surfer/standalone'
import { useSteps, useDeck } from 'gatsby-theme-mdx-deck'
import { parse } from "shell-quote"

import { useNotes } from './notes'

import styled from '@emotion/styled'

import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-ruby'
import 'prismjs/components/prism-sass'

import { useSpring, transform, AnimatePresence, motion  } from 'framer-motion'

import theme from './theme'

const Grid = styled('div')`
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "html js"
    "css js";
`

const SingleItemGrid = styled('div')`
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas:
    "single";
`

const GridItem = styled('div')`
  position: relative;
`

const HtmlGridItem = styled(GridItem)`
  grid-area: html;

  &.small > div {
    padding: 0 12.5%;
  }
`

const CssGridItem = styled(GridItem)`
  grid-area: css;
  border-top: 3px double rgba(0,0,0,.12);

  &.small > div {
    padding: 0 25% 0 15%;
  }
`

const JsGridItem = styled(GridItem)`
  grid-area: js;
  border-left: 3px double rgba(0,0,0,.12);
`

const SingleGridItem = styled(GridItem)`
  grid-area: single;
  margin: 16px;
  border: 3px double rgba(0,0,0,.12);
`

const InnerGridItem = styled(motion.div)`
  background-color: #f6f8fa;
  width: 100%;
  height: 100%;
  position: relative;
`

const Label = styled('h2')`
  font-size: 50%;
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 1;
  margin: 0;
  font-weight: normal;
  color: white;
  padding: 1%;
  background: rgba(0, 0, 0, .87);
  border-radius: 5%;
`

const OverlayWrapper = styled(motion.div)`
  width: 50%;
  height: 50%;
  position: absolute;
  background: white;
  border: 3px double;
  box-shadow: 0 0 5px rgba(0,0,0, .17);
`

export function RaamExampleLayout() {
  const totalSteps = 3
  const step = useSteps(totalSteps - 1)

  return (
    <Grid>
      <HtmlGridItem>
        <AnimatePresence>
          {step >= 0 && <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>HTML</InnerGridItem>}
        </AnimatePresence>
      </HtmlGridItem>
      <CssGridItem>
        <AnimatePresence>
          {step >= 1 && <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>CSS</InnerGridItem>}
        </AnimatePresence>
      </CssGridItem>
      <JsGridItem>
      <AnimatePresence>
          {step >= 2 && <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>JavaScript</InnerGridItem>}
        </AnimatePresence>
      </JsGridItem>
    </Grid>
  )
}


export function RaamLayout({ children, languages = ["HTML", "CSS", "JavaScript"], small = ['html', 'css'], stagger = false, Overlay = undefined }) {
  const context = useDeck()
  const steps = useMemo(getStepsFromChildren(children), [context.index, children])

  const notes = useMemo(() => steps.map((step, index) => [step.notesElement, Math.floor(index / 3), index]), [steps])
  const htmlSteps = useMemo(() => steps.filter((_, index) => index % 3 === 0), [steps])
  const cssSteps = useMemo(() => steps.filter((_, index) => index % 3 === 1), [steps])
  const jsSteps =  useMemo(() => steps.filter((_, index) => index % 3 === 2), [steps])

  const totalSteps = Math.floor(steps.length / 3) + (Overlay ? 1 : 0)
  const step = useSteps(totalSteps)
  const progress = useSpring(0, { damping: 45, stiffness: 500 })
  const [, forceRender] = useState(0)

  useNotes(notes)

  // Animate to next step
  useEffect(() => {
    if (Math.abs((step - progress.get())) >= 1) {
      progress.set(step)
    }

    let active = true

    progress.onRenderRequest(() => {
      active && forceRender((prev) => prev + 1)
    })

    return () => {
      active = false
    }

  }, [step, progress])

  const maxCodeSteps = Overlay ? totalSteps - 1 : totalSteps
  const currentProgress = transform(progress.get(), [0, maxCodeSteps], [0, maxCodeSteps])

  return (
    <>
      <Grid>
        <HtmlGridItem className={small.indexOf('html') === -1 ? '' : 'small'}>
          <AnimatePresence>
          {(step !== totalSteps) && (
            <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Label>{languages[0]}</Label>
              <CodeSurfer steps={htmlSteps} progress={currentProgress} theme={theme} />
            </InnerGridItem>
          )}
          </AnimatePresence>
        </HtmlGridItem>
        <CssGridItem className={small.indexOf('css') === -1 ? '' : 'small'}>
          <AnimatePresence>
            {(!stagger || step > 0) && step !== totalSteps && (
            <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Label>{languages[1]}</Label>
              <CodeSurfer steps={cssSteps} progress={currentProgress} theme={theme} />
            </InnerGridItem>
            )}
          </AnimatePresence>
        </CssGridItem>
        <JsGridItem className={small.indexOf('js') === -1 ? '' : 'small'}>
          <AnimatePresence>
            {(!stagger || step > 1) && step !== totalSteps && (
            <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Label>{languages[2]}</Label>
              <CodeSurfer steps={jsSteps} progress={currentProgress} theme={theme} />
            </InnerGridItem>
            )}
          </AnimatePresence>
        </JsGridItem>
      </Grid>
      <AnimatePresence>
        {Overlay && step === totalSteps - 1 && <OverlayWrapper initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0 }}>
          <Overlay />
        </OverlayWrapper>}
      </AnimatePresence>
    </>
  )
}


export function OneRaamLayout({ children, language = "Ruby", Overlay = undefined }) {
  const context = useDeck()
  const steps = useMemo(getStepsFromChildren(children), [context.index, children])

  const notes = useMemo(() => steps.map((step, index) => [step.notesElement, index]), [steps])
  const totalSteps = steps.length + (Overlay ? 1 : 0)
  const step = useSteps(totalSteps)
  const progress = useSpring(0, { damping: 45, stiffness: 500 })
  const [, forceRender] = useState(0)

  useNotes(notes)

  // Animate to next step
  useEffect(() => {
    if (Math.abs((step - progress.get())) >= 1) {
      progress.set(step)
    }

    let active = true

    progress.onRenderRequest(() => {
      active && step !== 0 && forceRender((prev) => prev + 1)
    })

    return () => {
      active = false
    }

  }, [step, progress])

  const maxCodeSteps = Overlay ? totalSteps - 1 : totalSteps
  const currentProgress = transform(progress.get(), [0, maxCodeSteps], [0, maxCodeSteps])

  return (
    <>
    <SingleItemGrid>
      <SingleGridItem>
        <AnimatePresence>
        {(step !== totalSteps) && (
          <InnerGridItem initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Label>{language}</Label>
            <CodeSurfer steps={steps} progress={currentProgress} theme={theme} />
          </InnerGridItem>
        )}
        </AnimatePresence>
      </SingleGridItem>
    </SingleItemGrid>
    <AnimatePresence>
      {Overlay && step === totalSteps - 1 && <OverlayWrapper initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0 }}>
        <Overlay />
      </OverlayWrapper>}
    </AnimatePresence>
    </>
  )
}


export function readStepFromElement(element) {
  if (element.props && element.props.mdxType === 'Code') {
    // wrap everything except [code, lang, focus] in {value}
    const stepEntries = Object.entries(element.props).map(([key, value]) => ({
      [key]: ["code", "focus", "lang"].includes(key) ? value : { value }
    }));
    return Object.assign({}, ...stepEntries);
  }

  if (!element.props.children || !element.props.children.props) {
    return null;
  }

  const { props } = element.props.children;
  const className = props.className;
  return {
    code: props.children,
    lang: className && className.substring("language-".length),
    ...parseMetastring(props.metastring)
  };
}

const getStepsFromChildren = (children) => () => {
  const kids = React.Children.toArray(children);
  return kids
    .map((child, i) => {
      const step = readStepFromElement(child);
      if (!step) {
        return undefined
      }

      const nextChild = kids[i + 1];
      if (
        nextChild &&
        nextChild.props &&
        nextChild.props.mdxType === 'Notes'
      ) {
        step.notesElement = nextChild;
      }
      return step;
    })
    .filter(x => x);
};

export function parseMetastring(metastring) {
  if (!metastring) {
    return {};
  }

  const argv = parse(metastring);

  const result = {};
  argv.forEach(arg => {
    const v = arg.toString()

    if (!v.includes("=")) {
      result.focus = arg;
    } else {
      const [key, value] = v.split(/=(.*)/);
      result[key] = value;
    }
  });
  return result;
}
