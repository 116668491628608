// @ts-check

import React, { useEffect } from 'react'
import { motion, useCycle } from 'framer-motion'

/** @typedef {{ tickers: string[]; interval: number }} TickerProps */

/**
 *
 * @param {TickerProps} props
 */
export function Ticker({ tickers, interval }) {
  const height = 96
  const padding = 12

  const [y, tick] = useCycle(...tickers.map((_, index) => index * height))

  useEffect(() => {
    let timeoutId;

    const onTick = () => {
      tick()
      timeoutId = setTimeout(onTick, interval)
    }

    timeoutId = setTimeout(onTick, interval)

    return () => clearTimeout(timeoutId)
  }, [interval, tickers, tick])

  return (
    <span style={{ display: 'inline-block', overflow: 'hidden', position: 'relative', height, background: 'black', verticalAlign: 'bottom' }}>
      <motion.ul animate={{ y: -y }} style={{ background: 'yellow', margin: 0, padding: 0 }}>
        {tickers.map((ticker, index) => (<li style={{ fontWeight: 'bold', fontSize: 72, margin: 0, padding, lineHeight: (height - padding - padding) + 'px', color: 'white', background: 'black' }} key={index}>{ticker}</li>))}
      </motion.ul>
    </span>
  )
}
