import { Head } from 'mdx-deck';
import { Logo } from "..\\..\\..\\..\\..\\..\\components\\Logo";
import { Ticker } from "..\\..\\..\\..\\..\\..\\components\\Ticker";
import { Demo } from "..\\..\\..\\..\\..\\..\\components\\Demo";
import { TestRunner } from "..\\..\\..\\..\\..\\..\\components\\TestRunner";
import { AppearingList } from "..\\..\\..\\..\\..\\..\\components\\AppearingList";
import { SlideButton } from "..\\..\\..\\..\\..\\..\\components\\SlideButton";
import { MyComponent } from "..\\..\\..\\..\\..\\..\\test";
import { AnimatedLayout, RaamLayout, OneRaamLayout } from "..\\..\\..\\..\\..\\..\\layouts";
import myTheme from "..\\..\\..\\..\\..\\..\\theme";
import React from 'react';
export default {
  Head: Head,
  Logo: Logo,
  Ticker: Ticker,
  Demo: Demo,
  TestRunner: TestRunner,
  AppearingList: AppearingList,
  SlideButton: SlideButton,
  MyComponent: MyComponent,
  AnimatedLayout: AnimatedLayout,
  RaamLayout: RaamLayout,
  OneRaamLayout: OneRaamLayout,
  myTheme: myTheme,
  React: React
};