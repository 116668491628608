import "core-js/modules/es6.array.find-index";
import "core-js/modules/es7.object.values";
import "core-js/modules/es6.array.reduce";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.some";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.map";
import "core-js/modules/es6.promise";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import "core-js/modules/es6.array.for-each";
import { __assign, __rest } from 'tslib';
import { useRef, useEffect, memo, forwardRef, createContext, useMemo, useContext, createElement, Fragment, useCallback, useState, useLayoutEffect, cloneElement, Children, isValidElement } from 'react';
import sync, { getFrameData, cancelSync } from 'framesync';
import { velocityPerSecond, distance, mix, interpolate, wrap } from '@popmotion/popcorn';
import styler, { createStylerFactory, buildStyleProperty, isTransformProp, transformProps, buildSVGAttrs } from 'stylefire';
import { invariant, warning } from 'hey-listen';
import { number, px, percent, degrees, vw, vh, color, complex } from 'style-value-types';
import { action, delay, tween, spring, keyframes as keyframes$1, inertia } from 'popmotion';
import * as easingLookup from '@popmotion/easing';
import { cubicBezier, linear } from '@popmotion/easing';
/**
 * Uses the ref that is passed in, or creates a new one
 * @param external - External ref
 * @internal
 */

function useExternalRef(external) {
  // We're conditionally calling `useRef` here which is sort of naughty as hooks
  // shouldn't be called conditionally. However, Framer Motion will break if this
  // condition changes anyway. It might be possible to use an invariant here to
  // make it explicit, but I expect changing `ref` is not normal behaviour.
  var ref = !external || typeof external === "function" ? useRef(null) : external;
  useEffect(function () {
    if (external && typeof external === "function") {
      external(ref.current);
      return function () {
        return external(null);
      };
    }
  }, []);
  return ref;
}

var isFloat = function isFloat(value) {
  return !isNaN(parseFloat(value));
};
/**
 * `MotionValue` is used to track the state and velocity of motion values.
 *
 * @public
 */


var MotionValue =
/** @class */
function () {
  /**
   * @param init - The initiating value
   * @param config - Optional configuration options
   *
   * -  `transformer`: A function to transform incoming values with.
   *
   * @internal
   */
  function MotionValue(init, _a) {
    var _this = this;

    var _b = _a === void 0 ? {} : _a,
        transformer = _b.transformer,
        parent = _b.parent;
    /**
     * Duration, in milliseconds, since last updating frame.
     *
     * @internal
     */


    this.timeDelta = 0;
    /**
     * Timestamp of the last time this `MotionValue` was updated.
     *
     * @internal
     */

    this.lastUpdated = 0;
    /**
     * Tracks whether this value can output a velocity. Currently this is only true
     * if the value is numerical, but we might be able to widen the scope here and support
     * other value types.
     *
     * @internal
     */

    this.canTrackVelocity = false;

    this.updateAndNotify = function (v, render) {
      if (render === void 0) {
        render = true;
      }

      _this.prev = _this.current;
      _this.current = _this.transformer ? _this.transformer(v) : v;

      if (_this.updateSubscribers && _this.prev !== _this.current) {
        _this.updateSubscribers.forEach(_this.notifySubscriber);
      }

      if (_this.children) {
        _this.children.forEach(_this.setChild);
      }

      if (render && _this.renderSubscribers) {
        _this.renderSubscribers.forEach(_this.notifySubscriber);
      } // Update timestamp


      var _a = getFrameData(),
          delta = _a.delta,
          timestamp = _a.timestamp;

      if (_this.lastUpdated !== timestamp) {
        _this.timeDelta = delta;
        _this.lastUpdated = timestamp;
        sync.postRender(_this.scheduleVelocityCheck);
      }
    };
    /**
     * Notify a subscriber with the latest value.
     *
     * This is an instanced and bound function to prevent generating a new
     * function once per frame.
     *
     * @param subscriber - The subscriber to notify.
     *
     * @internal
     */


    this.notifySubscriber = function (subscriber) {
      subscriber(_this.current);
    };
    /**
     * Schedule a velocity check for the next frame.
     *
     * This is an instanced and bound function to prevent generating a new
     * function once per frame.
     *
     * @internal
     */


    this.scheduleVelocityCheck = function () {
      return sync.postRender(_this.velocityCheck);
    };
    /**
     * Updates `prev` with `current` if the value hasn't been updated this frame.
     * This ensures velocity calculations return `0`.
     *
     * This is an instanced and bound function to prevent generating a new
     * function once per frame.
     *
     * @internal
     */


    this.velocityCheck = function (_a) {
      var timestamp = _a.timestamp;

      if (timestamp !== _this.lastUpdated) {
        _this.prev = _this.current;
      }
    };
    /**
     * Updates child `MotionValue`.
     *
     * @param child - Child `MotionValue`.
     *
     * @internal
     */


    this.setChild = function (child) {
      return child.set(_this.current);
    };

    this.parent = parent;
    this.transformer = transformer;
    this.set(init, false);
    this.canTrackVelocity = isFloat(this.current);
  }
  /**
   * Creates a new `MotionValue` that's subscribed to the output of this one.
   *
   * @param config - Optional configuration options
   *
   * -  `transformer`: A function to transform incoming values with.
   *
   * @internal
   */


  MotionValue.prototype.addChild = function (config) {
    if (config === void 0) {
      config = {};
    }

    var child = new MotionValue(this.current, __assign({
      parent: this
    }, config));
    if (!this.children) this.children = new Set();
    this.children.add(child);
    return child;
  };
  /**
   * Stops a `MotionValue` from being subscribed to this one.
   *
   * @param child - The subscribed `MotionValue`
   *
   * @internal
   */


  MotionValue.prototype.removeChild = function (child) {
    if (!this.children) {
      return;
    }

    this.children["delete"](child);
  };
  /**
   * Subscribes a subscriber function to a subscription list.
   *
   * @param subscriptions - A `Set` of subscribers.
   * @param subscription - A subscriber function.
   */


  MotionValue.prototype.subscribeTo = function (subscriptions, subscription) {
    var _this = this;

    var updateSubscriber = function updateSubscriber() {
      return subscription(_this.current);
    };

    subscriptions.add(updateSubscriber);
    return function () {
      return subscriptions["delete"](updateSubscriber);
    };
  };
  /**
   * Adds a function that will be notified when the `MotionValue` is updated.
   *
   * It returns a function that, when called, will cancel the subscription.
   *
   * When calling `onChange` inside a React component, it should be wrapped with the
   * `useEffect` hook. As it returns an unsubscribe function, this should be returned
   * from the `useEffect` function to ensure you don't add duplicate subscribers..
   *
   * @library
   *
   * ```jsx
   * function MyComponent() {
   *   const x = useMotionValue(0)
   *   const y = useMotionValue(0)
   *   const opacity = useMotionValue(1)
   *
   *   useEffect(() => {
   *     function updateOpacity() {
   *       const maxXY = Math.max(x.get(), y.get())
   *       const newOpacity = transform(maxXY, [0, 100], [1, 0])
   *       opacity.set(newOpacity)
   *     }
   *
   *     const unsubscribeX = x.onChange(updateOpacity)
   *     const unsubscribeY = y.onChange(updateOpacity)
   *
   *     return () => {
   *       unsubscribeX()
   *       unsubscribeY()
   *     }
   *   }, [])
   *
   *   return <Frame x={x} />
   * }
   * ```
   *
   * @motion
   *
   * ```jsx
   * export const MyComponent = () => {
   *   const x = useMotionValue(0)
   *   const y = useMotionValue(0)
   *   const opacity = useMotionValue(1)
   *
   *   useEffect(() => {
   *     function updateOpacity() {
   *       const maxXY = Math.max(x.get(), y.get())
   *       const newOpacity = transform(maxXY, [0, 100], [1, 0])
   *       opacity.set(newOpacity)
   *     }
   *
   *     const unsubscribeX = x.onChange(updateOpacity)
   *     const unsubscribeY = y.onChange(updateOpacity)
   *
   *     return () => {
   *       unsubscribeX()
   *       unsubscribeY()
   *     }
   *   }, [])
   *
   *   return <motion.div style={{ x }} />
   * }
   * ```
   *
   * @internalremarks
   *
   * We could look into a `useOnChange` hook if the above lifecycle management proves confusing.
   *
   * ```jsx
   * useOnChange(x, () => {})
   * ```
   *
   * @param subscriber - A function that receives the latest value.
   * @returns A function that, when called, will cancel this subscription.
   *
   * @public
   */


  MotionValue.prototype.onChange = function (subscription) {
    if (!this.updateSubscribers) this.updateSubscribers = new Set();
    return this.subscribeTo(this.updateSubscribers, subscription);
  };
  /**
   * Adds a function that will be notified when the `MotionValue` requests a render.
   *
   * @param subscriber - A function that's provided the latest value.
   * @returns A function that, when called, will cancel this subscription.
   *
   * @internal
   */


  MotionValue.prototype.onRenderRequest = function (subscription) {
    if (!this.renderSubscribers) this.renderSubscribers = new Set(); // Render immediately

    this.notifySubscriber(subscription);
    return this.subscribeTo(this.renderSubscribers, subscription);
  };
  /**
   * Attaches a passive effect to the `MotionValue`.
   *
   * @internal
   */


  MotionValue.prototype.attach = function (passiveEffect) {
    this.passiveEffect = passiveEffect;
  };
  /**
   * Sets the state of the `MotionValue`.
   *
   * @remarks
   *
   * ```jsx
   * const x = useMotionValue(0)
   * x.set(10)
   * ```
   *
   * @param latest - Latest value to set.
   * @param render - Whether to notify render subscribers. Defaults to `true`
   *
   * @public
   */


  MotionValue.prototype.set = function (v, render) {
    if (render === void 0) {
      render = true;
    }

    if (!render || !this.passiveEffect) {
      this.updateAndNotify(v, render);
    } else {
      this.passiveEffect(v, this.updateAndNotify);
    }
  };
  /**
   * Returns the latest state of `MotionValue`
   *
   * @returns - The latest state of `MotionValue`
   *
   * @public
   */


  MotionValue.prototype.get = function () {
    return this.current;
  };
  /**
   * Returns the latest velocity of `MotionValue`
   *
   * @returns - The latest velocity of `MotionValue`. Returns `0` if the state is non-numerical.
   *
   * @public
   */


  MotionValue.prototype.getVelocity = function () {
    // This could be isFloat(this.prev) && isFloat(this.current), but that would be wasteful
    return this.canTrackVelocity ? // These casts could be avoided if parseFloat would be typed better
    velocityPerSecond(parseFloat(this.current) - parseFloat(this.prev), this.timeDelta) : 0;
  };
  /**
   * Registers a new animation to control this `MotionValue`. Only one
   * animation can drive a `MotionValue` at one time.
   *
   * ```jsx
   * value.start()
   * ```
   *
   * @param animation - A function that starts the provided animation
   *
   * @internal
   */


  MotionValue.prototype.start = function (animation) {
    var _this = this;

    this.stop();
    return new Promise(function (resolve) {
      _this.stopAnimation = animation(resolve);
    }).then(function () {
      return _this.clearAnimation();
    });
  };
  /**
   * Stop the currently active animation.
   *
   * @public
   */


  MotionValue.prototype.stop = function () {
    if (this.stopAnimation) this.stopAnimation();
    this.clearAnimation();
  };
  /**
   * Returns `true` if this value is currently animating.
   *
   * @public
   */


  MotionValue.prototype.isAnimating = function () {
    return !!this.stopAnimation;
  };

  MotionValue.prototype.clearAnimation = function () {
    this.stopAnimation = null;
  };
  /**
   * Destroy and clean up subscribers to this `MotionValue`.
   *
   * The `MotionValue` hooks like `useMotionValue` and `useTransform` automatically
   * handle the lifecycle of the returned `MotionValue`, so this method is only necessary if you've manually
   * created a `MotionValue` via the `motionValue` function.
   *
   * @public
   */


  MotionValue.prototype.destroy = function () {
    this.updateSubscribers && this.updateSubscribers.clear();
    this.renderSubscribers && this.renderSubscribers.clear();
    this.parent && this.parent.removeChild(this);
    this.stop();
  };

  return MotionValue;
}();
/**
 * @internal
 */


function motionValue(init, opts) {
  return new MotionValue(init, opts);
}
/**
 * Creates a constant value over the lifecycle of a component.
 *
 * Even if `useMemo` is provided an empty array as its final argument, it doesn't offer
 * a guarantee that it won't re-run for performance reasons later on. By using `useConstant`
 * you can ensure that initialisers don't execute twice or more.
 */


function useConstant(init) {
  var ref = useRef(null);

  if (ref.current === null) {
    ref.current = init();
  }

  return ref.current;
}

var isMotionValue = function isMotionValue(value) {
  return value instanceof MotionValue;
};

var session = null;
var syncRenderSession = {
  isOpen: function isOpen() {
    return session !== null;
  },
  open: function open() {
    invariant(!session, "Sync render session already open");
    session = [];
  },
  flush: function flush() {
    invariant(session !== null, "No sync render session found");
    session && session.forEach(function (styler) {
      return styler.render();
    });
    session = null;
  },
  push: function push(styler) {
    invariant(session !== null, "No sync render session found");
    session && session.push(styler);
  }
}; // Creating a styler factory for the `onUpdate` prop allows all values
// to fire and the `onUpdate` prop will only fire once per frame

var updateStyler = createStylerFactory({
  onRead: function onRead() {
    return null;
  },
  onRender: function onRender(state, _a) {
    var onUpdate = _a.onUpdate;
    return onUpdate(state);
  }
});

var MotionValuesMap =
/** @class */
function () {
  function MotionValuesMap() {
    this.hasMounted = false;
    this.values = new Map();
    this.unsubscribers = new Map();
  }

  MotionValuesMap.prototype.has = function (key) {
    return this.values.has(key);
  };

  MotionValuesMap.prototype.set = function (key, value) {
    this.values.set(key, value);

    if (this.hasMounted) {
      this.bindValueToOutput(key, value);
    }
  };

  MotionValuesMap.prototype.get = function (key, defaultValue) {
    var value = this.values.get(key);

    if (value === undefined && defaultValue !== undefined) {
      value = new MotionValue(defaultValue);
      this.set(key, value);
    }

    return value;
  };

  MotionValuesMap.prototype.forEach = function (callback) {
    return this.values.forEach(callback);
  };

  MotionValuesMap.prototype.bindValueToOutput = function (key, value) {
    var _this = this;

    var onRender = function onRender(v) {
      return _this.output && _this.output(key, v);
    };

    var unsubscribeOnRender = value.onRenderRequest(onRender);

    var onChange = function onChange(v) {
      _this.onUpdate && _this.onUpdate.set(key, v);
    };

    var unsubscribeOnChange = value.onChange(onChange);

    if (this.unsubscribers.has(key)) {
      this.unsubscribers.get(key)();
    }

    this.unsubscribers.set(key, function () {
      unsubscribeOnRender();
      unsubscribeOnChange();
    });
  };

  MotionValuesMap.prototype.setOnUpdate = function (onUpdate) {
    this.onUpdate = undefined;

    if (onUpdate) {
      this.onUpdate = updateStyler({
        onUpdate: onUpdate
      });
    }
  };

  MotionValuesMap.prototype.setTransformTemplate = function (transformTemplate) {
    if (this.transformTemplate !== transformTemplate) {
      this.transformTemplate = transformTemplate;
      this.updateTransformTemplate();
    }
  };

  MotionValuesMap.prototype.getTransformTemplate = function () {
    return this.transformTemplate;
  };

  MotionValuesMap.prototype.updateTransformTemplate = function () {
    if (this.output) {
      this.output("transform", this.transformTemplate);
    }
  };

  MotionValuesMap.prototype.mount = function (output) {
    var _this = this;

    this.hasMounted = true;
    if (output) this.output = output;
    this.values.forEach(function (value, key) {
      return _this.bindValueToOutput(key, value);
    });
    this.updateTransformTemplate();
  };

  MotionValuesMap.prototype.unmount = function () {
    var _this = this;

    this.values.forEach(function (_value, key) {
      var unsubscribe = _this.unsubscribers.get(key);

      unsubscribe && unsubscribe();
    });
  };

  return MotionValuesMap;
}();

var specialMotionValueProps = new Set(["dragOriginX", "dragOriginY"]);

var useMotionValues = function useMotionValues(props) {
  var motionValues = useConstant(function () {
    var map = new MotionValuesMap();
    /**
     * Loop through every prop and add any detected `MotionValue`s. This is SVG-specific
     * code that should be extracted, perhaps considered hollistically with `useMotionStyles`.
     *
     * <motion.circle cx={motionValue(0)} />
     */

    for (var key in props) {
      if (isMotionValue(props[key]) && !specialMotionValueProps.has(key)) {
        map.set(key, props[key]);
      }
    }

    return map;
  });
  motionValues.setOnUpdate(props.onUpdate);
  motionValues.setTransformTemplate(props.transformTemplate);
  return motionValues;
};
/**
 * `useEffect` gets resolved bottom-up. We defer some optional functionality to child
 * components, so to ensure everything runs correctly we export the ref-binding logic
 * to a new component rather than in `useMotionValues`.
 */


var MountMotionValuesComponent = function MountMotionValuesComponent(_a, ref) {
  var values = _a.values,
      isStatic = _a.isStatic;
  useEffect(function () {
    invariant(ref.current instanceof Element, "No `ref` found. Ensure components created with `motion.custom` forward refs using `React.forwardRef`");
    var domStyler = styler(ref.current, {
      preparseOutput: false,
      enableHardwareAcceleration: !isStatic
    });
    values.mount(function (key, value) {
      domStyler.set(key, value);

      if (syncRenderSession.isOpen()) {
        syncRenderSession.push(domStyler);
      }
    });
    return function () {
      return values.unmount();
    };
  }, []);
  return null;
};

var MountMotionValues = memo(forwardRef(MountMotionValuesComponent));

var createValueResolver = function createValueResolver(resolver) {
  return function (values) {
    var resolvedValues = {};
    values.forEach(function (value, key) {
      return resolvedValues[key] = resolver(value);
    });
    return resolvedValues;
  };
};

var resolveCurrent = createValueResolver(function (value) {
  return value.get();
});
var transformOriginProps = new Set(["originX", "originY", "originZ"]);

var isTransformOriginProp = function isTransformOriginProp(key) {
  return transformOriginProps.has(key);
};

var buildStyleAttr = function buildStyleAttr(values, styleProp, isStatic) {
  var motionValueStyles = resolveCurrent(values);
  var transformTemplate = values.getTransformTemplate();

  if (transformTemplate) {
    // If `transform` has been manually set as a string, pass that through the template
    // otherwise pass it forward to Stylefire's style property builder
    motionValueStyles.transform = styleProp.transform ? transformTemplate({}, styleProp.transform) : transformTemplate;
  }

  return buildStyleProperty(__assign({}, styleProp, motionValueStyles), !isStatic);
};

var useMotionStyles = function useMotionStyles(values, styleProp, isStatic, transformValues) {
  if (styleProp === void 0) {
    styleProp = {};
  }

  var style = {};
  var prevMotionStyles = useRef({}).current;

  for (var key in styleProp) {
    var thisStyle = styleProp[key];

    if (isMotionValue(thisStyle)) {
      // If this is a motion value, add it to our MotionValuesMap
      values.set(key, thisStyle);
    } else if (!isStatic && (isTransformProp(key) || isTransformOriginProp(key))) {
      // Or if it's a transform prop, create a motion value (or update an existing one)
      // to ensure Stylefire can reconcile all the transform values together.
      // A further iteration on this would be to create a single styler per component that gets
      // used in the DOM renderer's buildStyleAttr *and* animations, then we would only
      // have to convert animating values to `MotionValues` (we could probably remove this entire function).
      // The only architectural consideration is to allow Stylefire to have elements mounted after
      // a styler is created.
      if (!values.has(key)) {
        // If it doesn't exist as a motion value, create it
        values.set(key, motionValue(thisStyle));
      } else {
        // Otherwise only update it if it's changed from a previous render
        if (thisStyle !== prevMotionStyles[key]) {
          var value = values.get(key);
          value.set(thisStyle);
        }
      }

      prevMotionStyles[key] = thisStyle;
    } else {
      style[key] = thisStyle;
    }
  }

  return transformValues ? transformValues(style) : style;
};

var isKeyframesTarget = function isKeyframesTarget(v) {
  return Array.isArray(v);
};

var isCustomValue = function isCustomValue(v) {
  return Boolean(v && typeof v === "object" && v.mix && v.toValue);
};

var resolveFinalValueInKeyframes = function resolveFinalValueInKeyframes(v) {
  // TODO maybe throw if v.length - 1 is placeholder token?
  return isKeyframesTarget(v) ? v[v.length - 1] || 0 : v;
};

var auto = {
  test: function test(v) {
    return v === "auto";
  },
  parse: function parse(v) {
    return v;
  }
};
var dimensionTypes = [number, px, percent, degrees, vw, vh, auto];
var valueTypes = dimensionTypes.concat([color, complex]);

var testValueType = function testValueType(v) {
  return function (type) {
    return type.test(v);
  };
};

var getDimensionValueType = function getDimensionValueType(v) {
  return dimensionTypes.find(testValueType(v));
};

var getValueType = function getValueType(v) {
  return valueTypes.find(testValueType(v));
};

var underDampedSpring = function underDampedSpring() {
  return {
    type: "spring",
    stiffness: 500,
    damping: 25,
    restDelta: 0.5,
    restSpeed: 10
  };
};

var overDampedSpring = function overDampedSpring(to) {
  return {
    type: "spring",
    stiffness: 700,
    damping: to === 0 ? 100 : 35
  };
};

var linearTween = function linearTween() {
  return {
    ease: "linear",
    duration: 0.3
  };
};

var keyframes = function keyframes(values) {
  return {
    type: "keyframes",
    duration: 0.8,
    values: values
  };
};

var defaultTransitions = {
  x: underDampedSpring,
  y: underDampedSpring,
  z: underDampedSpring,
  rotate: underDampedSpring,
  rotateX: underDampedSpring,
  rotateY: underDampedSpring,
  rotateZ: underDampedSpring,
  scaleX: overDampedSpring,
  scaleY: overDampedSpring,
  scale: overDampedSpring,
  opacity: linearTween,
  backgroundColor: linearTween,
  color: linearTween,
  "default": overDampedSpring
};

var getDefaultTransition = function getDefaultTransition(valueKey, to) {
  var transitionFactory;

  if (isKeyframesTarget(to)) {
    transitionFactory = keyframes;
  } else {
    transitionFactory = defaultTransitions[valueKey] || defaultTransitions["default"];
  }

  return __assign({
    to: to
  }, transitionFactory(to));
};
/**
 * A Popmotion action that accepts a single `to` prop. When it starts, it immediately
 * updates with `to` and then completes. By using this we can compose instant transitions
 * in with the same logic that applies `delay` or returns a `Promise` etc.
 *
 * Accepting `duration` is a little bit of a hack that simply defers the completetion of
 * the animation until after the duration finishes. This is for situations when you're **only**
 * animating non-animatable values and then setting something on `transitionEnd`. Really
 * you want this to fire after the "animation" finishes, rather than instantly.
 *
 * ```
 * animate={{
 *   display: 'block',
 *   transitionEnd: { display: 'none' }
 * }}
 * ```
 */


var just = function just(_a) {
  var to = _a.to,
      duration = _a.duration;
  return action(function (_a) {
    var update = _a.update,
        complete = _a.complete;
    update(to);
    duration ? delay(duration).start({
      complete: complete
    }) : complete();
  });
};

var easingDefinitionToFunction = function easingDefinitionToFunction(definition) {
  if (Array.isArray(definition)) {
    // If cubic bezier definition, create bezier curve
    invariant(definition.length === 4, "Cubic bezier arrays must contain four numerical values.");
    var x1 = definition[0],
        y1 = definition[1],
        x2 = definition[2],
        y2 = definition[3];
    return cubicBezier(x1, y1, x2, y2);
  } else if (typeof definition === "string") {
    // Else lookup from table
    invariant(easingLookup[definition] !== undefined, "Invalid easing type '" + definition + "'");
    return easingLookup[definition];
  }

  return definition;
};

var isEasingArray = function isEasingArray(ease) {
  return Array.isArray(ease) && typeof ease[0] !== "number";
};

var isDurationAnimation = function isDurationAnimation(v) {
  return v.hasOwnProperty("duration") || v.hasOwnProperty("repeatDelay");
};
/**
 * Check if a value is animatable. Examples:
 *
 * ✅: 100, "100px", "#fff"
 * ❌: "block", "url(2.jpg)"
 * @param value
 *
 * @internal
 */


var isAnimatable = function isAnimatable(key, value) {
  // If the list of keys tat might be non-animatable grows, replace with Set
  if (key === "zIndex") return false; // If it's a number or a keyframes array, we can animate it. We might at some point
  // need to do a deep isAnimatable check of keyframes, or let Popmotion handle this,
  // but for now lets leave it like this for performance reasons

  if (typeof value === "number" || Array.isArray(value)) return true;

  if (typeof value === "string" && // It's animatable if we have a string
  complex.test(value) && // And it contains numbers and/or colors
  !value.startsWith("url(") // Unless it starts with "url("
  ) {
    return true;
  }

  return false;
};
/**
 * Converts seconds to milliseconds
 *
 * @param seconds - Time in seconds.
 * @return milliseconds - Converted time in milliseconds.
 */


var secondsToMilliseconds = function secondsToMilliseconds(seconds) {
  return seconds * 1000;
};

var transitions = {
  tween: tween,
  spring: spring,
  keyframes: keyframes$1,
  inertia: inertia,
  just: just
};
var transitionOptionParser = {
  tween: function tween(opts) {
    if (opts.ease) {
      var ease = isEasingArray(opts.ease) ? opts.ease[0] : opts.ease;
      opts.ease = easingDefinitionToFunction(ease);
    }

    return opts;
  },
  keyframes: function keyframes(_a) {
    var from = _a.from,
        to = _a.to,
        velocity = _a.velocity,
        opts = __rest(_a, ["from", "to", "velocity"]);

    if (opts.values && opts.values[0] === null) {
      var values = opts.values.slice();
      values[0] = from;
      opts.values = values;
    }

    if (opts.ease) {
      opts.easings = isEasingArray(opts.ease) ? opts.ease.map(easingDefinitionToFunction) : easingDefinitionToFunction(opts.ease);
    }

    opts.ease = linear;
    return opts;
  }
};

var isTransitionDefined = function isTransitionDefined(_a) {
  var when = _a.when,
      delay = _a.delay,
      delayChildren = _a.delayChildren,
      staggerChildren = _a.staggerChildren,
      staggerDirection = _a.staggerDirection,
      transition = __rest(_a, ["when", "delay", "delayChildren", "staggerChildren", "staggerDirection"]);

  return Object.keys(transition).length;
};

var getTransitionDefinition = function getTransitionDefinition(key, to, transitionDefinition) {
  var delay = transitionDefinition ? transitionDefinition.delay : 0; // If no object, return default transition
  // A better way to handle this would be to deconstruct out all the shared Orchestration props
  // and see if there's any props remaining

  if (transitionDefinition === undefined || !isTransitionDefined(transitionDefinition)) {
    return __assign({
      delay: delay
    }, getDefaultTransition(key, to));
  }

  var valueTransitionDefinition = transitionDefinition[key] || transitionDefinition["default"] || transitionDefinition;

  if (valueTransitionDefinition.type === false) {
    return {
      delay: valueTransitionDefinition.hasOwnProperty("delay") ? valueTransitionDefinition.delay : delay,
      to: isKeyframesTarget(to) ? to[to.length - 1] : to,
      type: "just"
    };
  } else if (isKeyframesTarget(to)) {
    return __assign({
      values: to,
      duration: 0.8,
      delay: delay,
      ease: "linear"
    }, valueTransitionDefinition, {
      // This animation must be keyframes if we're animating through an array
      type: "keyframes"
    });
  } else {
    return __assign({
      type: "tween",
      to: to,
      delay: delay
    }, valueTransitionDefinition);
  }
};

var preprocessOptions = function preprocessOptions(type, opts) {
  return transitionOptionParser[type] ? transitionOptionParser[type](opts) : opts;
};

var getAnimation = function getAnimation(key, value, target, transition) {
  var origin = value.get();
  var isOriginAnimatable = isAnimatable(key, origin);
  var isTargetAnimatable = isAnimatable(key, target); // TODO we could probably improve this check to ensure both values are of the same type -
  // for instance 100 to #fff. This might live better in Popmotion.

  warning(isOriginAnimatable === isTargetAnimatable, "You are trying to animate " + key + " from \"" + origin + "\" to " + target + ". \"" + origin + "\" is not an animatable value - to enable this animation set " + origin + " to a value animatable to " + target + " via the `style` property."); // Parse the `transition` prop and return options for the Popmotion animation

  var _a = getTransitionDefinition(key, target, transition),
      _b = _a.type,
      type = _b === void 0 ? "tween" : _b,
      transitionDefinition = __rest(_a, ["type"]); // If this is an animatable pair of values, return an animation, otherwise use `just`


  var actionFactory = isOriginAnimatable && isTargetAnimatable ? transitions[type] : just;
  var opts = preprocessOptions(type, __assign({
    from: origin,
    velocity: value.getVelocity()
  }, transitionDefinition)); // Convert duration from Framer Motion's seconds into Popmotion's milliseconds

  if (isDurationAnimation(opts)) {
    if (opts.duration) {
      opts.duration = secondsToMilliseconds(opts.duration);
    }

    if (opts.repeatDelay) {
      opts.repeatDelay = secondsToMilliseconds(opts.repeatDelay);
    }
  }

  return [actionFactory, opts];
};
/**
 * Start animation on a value. This function completely encapsulates Popmotion-specific logic.
 *
 * @internal
 */


function startAnimation(key, value, target, _a) {
  var _b = _a.delay,
      delay$1 = _b === void 0 ? 0 : _b,
      transition = __rest(_a, ["delay"]);

  return value.start(function (complete) {
    var activeAnimation;

    var _a = getAnimation(key, value, target, transition),
        animationFactory = _a[0],
        _b = _a[1],
        valueDelay = _b.delay,
        options = __rest(_b, ["delay"]);

    if (valueDelay !== undefined) {
      delay$1 = valueDelay;
    }

    var animate = function animate() {
      var animation = animationFactory(options); // Bind animation opts to animation

      activeAnimation = animation.start({
        update: function update(v) {
          return value.set(v);
        },
        complete: complete
      });
    }; // If we're delaying this animation, only resolve it **after** the delay to
    // ensure the value's resolve velocity is up-to-date.


    if (delay$1) {
      activeAnimation = delay(secondsToMilliseconds(delay$1)).start({
        complete: animate
      });
    } else {
      animate();
    }

    return function () {
      if (activeAnimation) activeAnimation.stop();
    };
  });
}
/**
 * Get the current value of every `MotionValue`
 * @param values -
 */


var getCurrent = function getCurrent(values) {
  var current = {};
  values.forEach(function (value, key) {
    return current[key] = value.get();
  });
  return current;
};
/**
 * Get the current velocity of every `MotionValue`
 * @param values -
 */


var getVelocity = function getVelocity(values) {
  var velocity = {};
  values.forEach(function (value, key) {
    return velocity[key] = value.getVelocity();
  });
  return velocity;
};
/**
 * Check if value is a function that returns a `Target`. A generic typeof === 'function'
 * check, just helps with typing.
 * @param p -
 */


var isTargetResolver = function isTargetResolver(p) {
  return typeof p === "function";
};
/**
 * Check if value is a list of variant labels
 * @param v -
 */


var isVariantLabels = function isVariantLabels(v) {
  return Array.isArray(v);
};
/**
 * Check if value is a numerical string, ie "100" or "100px"
 */


var isNumericalString = function isNumericalString(v) {
  return /^\d*\.?\d+$/.test(v);
};
/**
 * Control animations for a single component
 * @internal
 */


var ValueAnimationControls =
/** @class */
function () {
  function ValueAnimationControls(_a) {
    var _this = this;

    var values = _a.values,
        readValueFromSource = _a.readValueFromSource,
        makeTargetAnimatable = _a.makeTargetAnimatable;
    /**
     * The component's variants, as provided by `variants`
     */

    this.variants = {};
    /**
     * A set of values that we animate back to when a value is cleared of all overrides.
     */

    this.baseTarget = {};
    /**
     * A series of target overrides that we can animate to/from when overrides are set/cleared.
     */

    this.overrides = [];
    /**
     * A series of target overrides as they were originally resolved.
     */

    this.resolvedOverrides = [];
    /**
     * A Set of currently active override indexes
     */

    this.activeOverrides = new Set();
    /**
     * A Set of value keys that are currently animating.
     */

    this.isAnimating = new Set();
    /**
     * Check if the associated `MotionValueMap` has a key with the provided string.
     * Pre-bound to the class so we can provide directly to the `filter` in `checkForNewValues`.
     */

    this.hasValue = function (key) {
      return !_this.values.has(key);
    };

    this.values = values;
    this.readValueFromSource = readValueFromSource;
    this.makeTargetAnimatable = makeTargetAnimatable;
    this.values.forEach(function (value, key) {
      return _this.baseTarget[key] = value.get();
    });
  }
  /**
   * Set the reference to the component's props.
   * @param props -
   */


  ValueAnimationControls.prototype.setProps = function (props) {
    this.props = props;
  };
  /**
   * Set the reference to the component's variants
   * @param variants -
   */


  ValueAnimationControls.prototype.setVariants = function (variants) {
    if (variants) this.variants = variants;
  };
  /**
   * Set the component's default transition
   * @param transition -
   */


  ValueAnimationControls.prototype.setDefaultTransition = function (transition) {
    if (transition) this.defaultTransition = transition;
  };
  /**
   * Set motion values without animation.
   *
   * @param target -
   * @param isActive -
   */


  ValueAnimationControls.prototype.setValues = function (_a, _b) {
    var _this = this;

    var _c = _b === void 0 ? {} : _b,
        _d = _c.isActive,
        isActive = _d === void 0 ? new Set() : _d,
        priority = _c.priority;

    var transition = _a.transition,
        transitionEnd = _a.transitionEnd,
        target = __rest(_a, ["transition", "transitionEnd"]);

    target = this.transformValues(__assign({}, target, transitionEnd));
    return Object.keys(target).forEach(function (key) {
      if (isActive.has(key)) return;
      isActive.add(key);
      var targetValue = resolveFinalValueInKeyframes(target[key]);

      if (_this.values.has(key)) {
        var value = _this.values.get(key);

        value && value.set(targetValue);
      } else {
        _this.values.set(key, motionValue(targetValue));
      }

      if (!priority) _this.baseTarget[key] = targetValue;
    });
  };
  /**
   * Allows `transformValues` to be set by a component that allows us to
   * transform the values in a given `Target`. This allows Framer Library
   * to extend Framer Motion to animate `Color` variables etc. Currently we have
   * to manually support these extended types here in Framer Motion.
   *
   * @param values -
   */


  ValueAnimationControls.prototype.transformValues = function (values) {
    var transformValues = this.props.transformValues;
    return transformValues ? transformValues(values) : values;
  };
  /**
   * Check a `Target` for new values we haven't animated yet, and add them
   * to the `MotionValueMap`.
   *
   * Currently there's functionality here that is DOM-specific, we should allow
   * this functionality to be injected by the factory that creates DOM-specific
   * components.
   *
   * @param target -
   */


  ValueAnimationControls.prototype.checkForNewValues = function (target) {
    var newValueKeys = Object.keys(target).filter(this.hasValue);
    var numNewValues = newValueKeys.length;
    if (!numNewValues) return;

    for (var i = 0; i < numNewValues; i++) {
      var key = newValueKeys[i];
      var targetValue = target[key];
      var value = null; // If this is a keyframes value, we can attempt to use the first value in the
      // array as that's going to be the first value of the animation anyway

      if (Array.isArray(targetValue)) {
        value = targetValue[0];
      } // If it isn't a keyframes or the first keyframes value was set as `null`, read the
      // value from the DOM. It might be worth investigating whether to check props (for SVG)
      // or props.style (for HTML) if the value exists there before attempting to read.


      if (value === null) {
        value = this.readValueFromSource(key);
        invariant(value !== null, "No initial value for \"" + key + "\" can be inferred. Ensure an initial value for \"" + key + "\" is defined on the component.");
      }

      if (typeof value === "string" && isNumericalString(value)) {
        // If this is a number read as a string, ie "0" or "200", convert it to a number
        value = parseFloat(value);
      } else if (!getValueType(value) && complex.test(targetValue)) {
        // If value is not recognised as animatable, ie "none", create an animatable version origin based on the target
        value = complex.getAnimatableNone(targetValue);
      }

      this.values.set(key, motionValue(value));
      this.baseTarget[key] = value;
    }
  };
  /**
   * Resolve a variant from its label or resolver into an actual `Target` we can animate to.
   * @param variant -
   */


  ValueAnimationControls.prototype.resolveVariant = function (variant) {
    if (!variant) {
      return {
        target: undefined,
        transition: undefined,
        transitionEnd: undefined
      };
    }

    if (isTargetResolver(variant)) {
      // resolve current and velocity
      variant = variant(this.props.custom, getCurrent(this.values), getVelocity(this.values));
    }

    var _a = variant.transition,
        transition = _a === void 0 ? this.defaultTransition : _a,
        transitionEnd = variant.transitionEnd,
        target = __rest(variant, ["transition", "transitionEnd"]);

    return {
      transition: transition,
      transitionEnd: transitionEnd,
      target: target
    };
  };
  /**
   * Get the highest active override priority index
   */


  ValueAnimationControls.prototype.getHighestPriority = function () {
    if (!this.activeOverrides.size) return 0;
    return Math.max.apply(Math, Array.from(this.activeOverrides));
  };
  /**
   * Set an override. We add this layer of indirection so if, for instance, a tap gesture
   * starts and overrides a hover gesture, when we clear the tap gesture and fallback to the
   * hover gesture, if that hover gesture has changed in the meantime we can go to that rather
   * than the one that was resolved when the hover gesture animation started.
   *
   * @param definition -
   * @param overrideIndex -
   */


  ValueAnimationControls.prototype.setOverride = function (definition, overrideIndex) {
    this.overrides[overrideIndex] = definition;

    if (this.children) {
      this.children.forEach(function (child) {
        return child.setOverride(definition, overrideIndex);
      });
    }
  };
  /**
   * Start an override animation.
   * @param overrideIndex -
   */


  ValueAnimationControls.prototype.startOverride = function (overrideIndex) {
    var override = this.overrides[overrideIndex];

    if (override) {
      return this.start(override, {
        priority: overrideIndex
      });
    }
  };
  /**
   * Clear an override. We check every value we animated to in this override to see if
   * its present on any lower-priority overrides. If not, we animate it back to its base target.
   * @param overrideIndex -
   */


  ValueAnimationControls.prototype.clearOverride = function (overrideIndex) {
    var _this = this;

    if (this.children) {
      this.children.forEach(function (child) {
        return child.clearOverride(overrideIndex);
      });
    }

    var override = this.overrides[overrideIndex];
    if (!override) return;
    this.activeOverrides["delete"](overrideIndex);
    var highest = this.getHighestPriority();
    this.resetIsAnimating();

    if (highest) {
      var highestOverride = this.overrides[highest];
      highestOverride && this.startOverride(highest);
    } // Figure out which remaining values were affected by the override and animate those


    var overrideTarget = this.resolvedOverrides[overrideIndex];
    if (!overrideTarget) return;
    var remainingValues = {};

    for (var key in this.baseTarget) {
      if (overrideTarget[key] !== undefined) {
        remainingValues[key] = this.baseTarget[key];
      }
    }

    this.onStart();
    this.animate(remainingValues).then(function () {
      return _this.onComplete();
    });
  };
  /**
   * Apply a target/variant without any animation
   */


  ValueAnimationControls.prototype.apply = function (definition) {
    if (Array.isArray(definition)) {
      return this.applyVariantLabels(definition);
    } else if (typeof definition === "string") {
      return this.applyVariantLabels([definition]);
    } else {
      this.setValues(definition);
    }
  };
  /**
   * Apply variant labels without animation
   */


  ValueAnimationControls.prototype.applyVariantLabels = function (variantLabelList) {
    var _this = this;

    var isActive = new Set();
    var reversedList = variantLabelList.slice().reverse();
    reversedList.forEach(function (key) {
      var _a = _this.resolveVariant(_this.variants[key]),
          target = _a.target,
          transitionEnd = _a.transitionEnd;

      if (transitionEnd) {
        _this.setValues(transitionEnd, {
          isActive: isActive
        });
      }

      if (target) {
        _this.setValues(target, {
          isActive: isActive
        });
      }

      if (_this.children && _this.children.size) {
        _this.children.forEach(function (child) {
          return child.applyVariantLabels(variantLabelList);
        });
      }
    });
  };

  ValueAnimationControls.prototype.start = function (definition, opts) {
    var _this = this;

    if (opts === void 0) {
      opts = {};
    }

    if (opts.priority) {
      this.activeOverrides.add(opts.priority);
    }

    this.resetIsAnimating(opts.priority);
    var animation;

    if (isVariantLabels(definition)) {
      animation = this.animateVariantLabels(definition, opts);
    } else if (typeof definition === "string") {
      animation = this.animateVariant(definition, opts);
    } else {
      animation = this.animate(definition, opts);
    }

    this.onStart();
    return animation.then(function () {
      return _this.onComplete();
    });
  };

  ValueAnimationControls.prototype.animate = function (animationDefinition, _a) {
    var _this = this;

    var _b = _a === void 0 ? {} : _a,
        _c = _b.delay,
        delay = _c === void 0 ? 0 : _c,
        _d = _b.priority,
        priority = _d === void 0 ? 0 : _d,
        transitionOverride = _b.transitionOverride;

    var _e = this.resolveVariant(animationDefinition),
        target = _e.target,
        transition = _e.transition,
        transitionEnd = _e.transitionEnd;

    if (transitionOverride) {
      transition = transitionOverride;
    }

    if (!target) return Promise.resolve();
    target = this.transformValues(target);

    if (transitionEnd) {
      transitionEnd = this.transformValues(transitionEnd);
    }

    this.checkForNewValues(target);

    if (this.makeTargetAnimatable) {
      var animatable = this.makeTargetAnimatable(target, transitionEnd);
      target = animatable.target;
      transitionEnd = animatable.transitionEnd;
    }

    if (priority) {
      this.resolvedOverrides[priority] = target;
    }

    this.checkForNewValues(target);
    var animations = [];

    for (var key in target) {
      var value = this.values.get(key);
      if (!value || !target || target[key] === undefined) continue;
      var valueTarget = target[key];

      if (!priority) {
        this.baseTarget[key] = resolveFinalValueInKeyframes(valueTarget);
      }

      if (this.isAnimating.has(key)) continue;
      this.isAnimating.add(key);
      animations.push(startAnimation(key, value, valueTarget, __assign({
        delay: delay
      }, transition)));
    }

    var allAnimations = Promise.all(animations);
    return transitionEnd ? allAnimations.then(function () {
      _this.setValues(transitionEnd, {
        priority: priority
      });
    }) : allAnimations;
  };

  ValueAnimationControls.prototype.animateVariantLabels = function (variantLabels, opts) {
    var _this = this;

    var animations = variantLabels.slice().reverse().map(function (label) {
      return _this.animateVariant(label, opts);
    });
    return Promise.all(animations);
  };

  ValueAnimationControls.prototype.animateVariant = function (variantLabel, opts) {
    var _this = this;

    var when = false;
    var delayChildren = 0;
    var staggerChildren = 0;
    var staggerDirection = 1;
    var priority = opts && opts.priority || 0;
    var variant = this.variants[variantLabel];
    var getAnimations = variant ? function () {
      return _this.animate(variant, opts);
    } : function () {
      return Promise.resolve();
    };
    var getChildrenAnimations = this.children ? function () {
      return _this.animateChildren(variantLabel, delayChildren, staggerChildren, staggerDirection, priority);
    } : function () {
      return Promise.resolve();
    };

    if (variant && this.children) {
      var transition = this.resolveVariant(variant).transition;

      if (transition) {
        when = transition.when || when;
        delayChildren = transition.delayChildren || delayChildren;
        staggerChildren = transition.staggerChildren || staggerChildren;
        staggerDirection = transition.staggerDirection || staggerDirection;
      }
    }

    if (when) {
      var _a = when === "beforeChildren" ? [getAnimations, getChildrenAnimations] : [getChildrenAnimations, getAnimations],
          first = _a[0],
          last = _a[1];

      return first().then(last);
    } else {
      return Promise.all([getAnimations(), getChildrenAnimations()]);
    }
  };

  ValueAnimationControls.prototype.animateChildren = function (variantLabel, delayChildren, staggerChildren, staggerDirection, priority) {
    if (delayChildren === void 0) {
      delayChildren = 0;
    }

    if (staggerChildren === void 0) {
      staggerChildren = 0;
    }

    if (staggerDirection === void 0) {
      staggerDirection = 1;
    }

    if (priority === void 0) {
      priority = 0;
    }

    if (!this.children) {
      return Promise.resolve();
    }

    var animations = [];
    var maxStaggerDuration = (this.children.size - 1) * staggerChildren;
    var generateStaggerDuration = staggerDirection === 1 ? function (i) {
      return i * staggerChildren;
    } : function (i) {
      return maxStaggerDuration - i * staggerChildren;
    };
    Array.from(this.children).forEach(function (childControls, i) {
      var animation = childControls.animateVariant(variantLabel, {
        priority: priority,
        delay: delayChildren + generateStaggerDuration(i)
      });
      animations.push(animation);
    });
    return Promise.all(animations);
  };

  ValueAnimationControls.prototype.onStart = function () {
    var onAnimationStart = this.props.onAnimationStart;
    onAnimationStart && onAnimationStart();
  };

  ValueAnimationControls.prototype.onComplete = function () {
    var onAnimationComplete = this.props.onAnimationComplete;
    onAnimationComplete && onAnimationComplete();
  };

  ValueAnimationControls.prototype.checkOverrideIsAnimating = function (priority) {
    var numOverrides = this.overrides.length;

    for (var i = priority + 1; i < numOverrides; i++) {
      var resolvedOverride = this.resolvedOverrides[i];

      if (resolvedOverride) {
        for (var key in resolvedOverride) {
          this.isAnimating.add(key);
        }
      }
    }
  };

  ValueAnimationControls.prototype.resetIsAnimating = function (priority) {
    if (priority === void 0) {
      priority = 0;
    }

    this.isAnimating.clear(); // If this isn't the highest priority gesture, block the animation
    // of anything that's currently being animated

    if (priority < this.getHighestPriority()) {
      this.checkOverrideIsAnimating(priority);
    }

    if (this.children) {
      this.children.forEach(function (child) {
        return child.resetIsAnimating(priority);
      });
    }
  };

  ValueAnimationControls.prototype.stop = function () {
    this.values.forEach(function (value) {
      return value.stop();
    });
  };
  /**
   * Add the controls of a child component.
   * @param controls -
   */


  ValueAnimationControls.prototype.addChild = function (controls) {
    if (!this.children) {
      this.children = new Set();
    }

    this.children.add(controls); // We set child overrides when `setOverride` is called, but also have to do it here
    // as the first time `setOverride` is called all the children might not have been added yet.

    this.overrides.forEach(function (override, i) {
      override && controls.setOverride(override, i);
    });
  };

  ValueAnimationControls.prototype.removeChild = function (controls) {
    if (!this.children) {
      return;
    }

    this.children["delete"](controls);
  };

  ValueAnimationControls.prototype.resetChildren = function () {
    if (this.children) this.children.clear();
  };

  return ValueAnimationControls;
}();
/**
 * Use a callback a maximum number of times
 * @param callback - Callback to run
 * @param times - Maximum number of times to run the callback. Defaults to `1`
 *
 * @public
 */


function useMaxTimes(callback, times) {
  if (times === void 0) {
    times = 1;
  }

  var count = useRef(0);
  if (count.current++ < times) callback();
}
/**
 * Control animations on one or more components.
 *
 * @public
 */


var AnimationControls =
/** @class */
function () {
  function AnimationControls() {
    /**
     * Track whether the host component has mounted.
     *
     * @internal
     */
    this.hasMounted = false;
    /**
     * Pending animations that are started before a component is mounted.
     *
     * @internal
     */

    this.pendingAnimations = [];
    /**
     * A collection of linked component animation controls.
     *
     * @internal
     */

    this.componentControls = new Set();
  }
  /**
   * Set variants on this and all child components.
   *
   * @param variants - The variants to set
   *
   * @internal
   */


  AnimationControls.prototype.setVariants = function (variants) {
    this.variants = variants;
    this.componentControls.forEach(function (controls) {
      return controls.setVariants(variants);
    });
  };
  /**
   * Set a default transition on this and all child components
   *
   * @param transition - The default transition to set
   *
   * @internal
   */


  AnimationControls.prototype.setDefaultTransition = function (transition) {
    this.defaultTransition = transition;
    this.componentControls.forEach(function (controls) {
      return controls.setDefaultTransition(transition);
    });
  };
  /**
   * Subscribes a component's animation controls to this.
   *
   * @param controls - The controls to subscribe
   * @returns An unsubscribe function.
   *
   * @internal
   */


  AnimationControls.prototype.subscribe = function (controls) {
    var _this = this;

    this.componentControls.add(controls);
    if (this.variants) controls.setVariants(this.variants);
    if (this.defaultTransition) controls.setDefaultTransition(this.defaultTransition);
    return function () {
      return _this.componentControls["delete"](controls);
    };
  };
  /**
   * Starts an animation on all linked components.
   *
   * @remarks
   *
   * ```jsx
   * controls.start("variantLabel")
   * controls.start({
   *   x: 0,
   *   transition: { duration: 1 }
   * })
   * ```
   *
   * @param definition - Properties or variant label to animate to
   * @param transition - Optional `transtion` to apply to a variant
   * @returns - A `Promise` that resolves when all animations have completed.
   *
   * @public
   */


  AnimationControls.prototype.start = function (definition, transitionOverride) {
    var _this = this;

    if (this.hasMounted) {
      var animations_1 = [];
      this.componentControls.forEach(function (controls) {
        var animation = controls.start(definition, {
          transitionOverride: transitionOverride
        });
        animations_1.push(animation);
      });
      return Promise.all(animations_1);
    } else {
      return new Promise(function (resolve) {
        _this.pendingAnimations.push({
          animation: [definition, transitionOverride],
          resolve: resolve
        });
      });
    }
  };
  /**
   * Instantly set to a set of properties or a variant.
   *
   * ```jsx
   * // With properties
   * controls.set({ opacity: 0 })
   *
   * // With variants
   * controls.set("hidden")
   * ```
   *
   * @internalremarks
   * We could perform a similar trick to `.start` where this can be called before mount
   * and we maintain a list of of pending actions that get applied on mount. But the
   * expectation of `set` is that it happens synchronously and this would be difficult
   * to do before any children have even attached themselves. It's also poor practise
   * and we should discourage render-synchronous `.start` calls rather than lean into this.
   *
   * @public
   */


  AnimationControls.prototype.set = function (definition) {
    invariant(this.hasMounted, "controls.set() should only be called after a component has mounted. Consider calling within a useEffect hook.");
    return this.componentControls.forEach(function (controls) {
      return controls.apply(definition);
    });
  };
  /**
   * Stops animations on all linked components.
   *
   * ```jsx
   * controls.stop()
   * ```
   *
   * @public
   */


  AnimationControls.prototype.stop = function () {
    this.componentControls.forEach(function (controls) {
      return controls.stop();
    });
  };
  /**
   * Initialises the animation controls.
   *
   * @internal
   */


  AnimationControls.prototype.mount = function () {
    var _this = this;

    this.hasMounted = true;
    this.pendingAnimations.forEach(function (_a) {
      var animation = _a.animation,
          resolve = _a.resolve;
      return _this.start.apply(_this, animation).then(resolve);
    });
  };
  /**
   * Stops all child animations when the host component unmounts.
   *
   * @internal
   */


  AnimationControls.prototype.unmount = function () {
    this.hasMounted = false;
    this.stop();
  };

  return AnimationControls;
}();
/**
 * @internal
 */


var animationControls = function animationControls() {
  return new AnimationControls();
};
/**
 * @internal
 */


var MotionContext = createContext({
  "static": false
});

var isVariantLabel = function isVariantLabel(v) {
  return typeof v === "string" || Array.isArray(v);
};

var isAnimationControls = function isAnimationControls(v) {
  return v instanceof AnimationControls;
};
/**
 * Set up the context for children motion components.
 *
 * We also use this opportunity to apply `initial` values
 */


var useMotionContext = function useMotionContext(parentContext, controls, values, isStatic, _a) {
  if (isStatic === void 0) {
    isStatic = false;
  }

  var initial = _a.initial,
      animate = _a.animate,
      variants = _a.variants,
      whileTap = _a.whileTap,
      whileHover = _a.whileHover;
  var initialState;

  if (initial === false && !isAnimationControls(animate)) {
    initialState = animate;
  } else if (typeof initial !== "boolean") {
    initialState = initial;
  } // Track mounted status so children can detect whether they were present during their
  // parent's first render


  var hasMounted = useRef(false); // We propagate this component's ValueAnimationControls *if* we're being provided variants,
  // if we're being used to control variants, or if we're being passed animation controls.
  // Otherwise this component should be "invisible" to variant propagation. This is a slight concession
  // to Framer X where every `Frame` is a `motion` component and it might be if we change that in the future
  // that this restriction is removed.

  var shouldPropagateControls = variants || isVariantLabel(animate) || isVariantLabel(whileTap) || isVariantLabel(whileHover) || isAnimationControls(animate); // If this component's `initial` prop is a variant label, propagate it. Otherwise pass the parent's.

  var targetInitial = isVariantLabel(initialState) ? initialState : parentContext.initial; // If this is a variant tree we need to propagate the `animate` prop in case new children are added after
  // the tree initially animates.

  var targetAnimate = isVariantLabel(animate) ? animate : parentContext.animate; // Only allow `initial` to trigger context re-renders if this is a `static` component (ie we're on the Framer canvas)
  // or in another non-animation/interaction environment.

  var initialDependency = isStatic ? targetInitial : null; // Only allow `animate` to trigger context re-renders if it's a variant label. If this is an array of
  // variant labels there's probably an optimisation to deep-compare but it might be an over-optimisation.
  // We want to do this as we rely on React's component rendering order each render cycle to determine
  // the new order of any child components for the `staggerChildren` functionality.

  var animateDependency = shouldPropagateControls && isVariantLabel(targetAnimate) ? targetAnimate : null; // The context to provide to the child. We `useMemo` because although `controls` and `initial` are
  // unlikely to change, by making the context an object it'll be considered a new value every render.
  // So all child motion components will re-render as a result.

  var context = useMemo(function () {
    return {
      controls: shouldPropagateControls ? controls : parentContext.controls,
      initial: targetInitial,
      animate: targetAnimate,
      values: values,
      hasMounted: hasMounted
    };
  }, [initialDependency, animateDependency]); // Update the `static` property every render. This is unlikely to change but also essentially free.

  context["static"] = isStatic; // Set initial state. If this is a static component (ie in Framer canvas), respond to updates
  // in `initial`.

  useMaxTimes(function () {
    var initialToApply = initialState || parentContext.initial;
    initialToApply && controls.apply(initialToApply);
  }, isStatic ? Infinity : 1);
  useEffect(function () {
    hasMounted.current = true;
  }, []);
  return context;
};
/**
 * Creates an imperative set of controls to trigger animations.
 *
 * This allows a consolidated, uniform API for animations, to be triggered by other APIs like the `animate` prop, or the gesture handlers.
 *
 * @param values
 * @param props
 * @param ref
 * @param subscribeToParentControls
 *
 * @internal
 */


function useValueAnimationControls(config, props, subscribeToParentControls) {
  var variants = props.variants,
      transition = props.transition;
  var parentControls = useContext(MotionContext).controls;
  var controls = useConstant(function () {
    return new ValueAnimationControls(config);
  }); // Reset and resubscribe children every render to ensure stagger order is correct

  controls.resetChildren();
  controls.setProps(props);
  controls.setVariants(variants);
  controls.setDefaultTransition(transition);

  if (subscribeToParentControls && parentControls) {
    parentControls.addChild(controls);
  }

  useEffect(function () {
    return function () {
      // Remove reference to onAnimationComplete from controls. All the MotionValues
      // are unsubscribed from this component separately. We let animations run out
      // as they might be animating other components.
      var onAnimationComplete = props.onAnimationComplete,
          unmountProps = __rest(props, ["onAnimationComplete"]);

      controls.setProps(unmountProps);
      parentControls && parentControls.removeChild(controls);
    };
  }, []);
  return controls;
}

function applyExitProps(props, _a) {
  var initial = _a.initial,
      isExiting = _a.isExiting,
      custom = _a.custom,
      onExitComplete = _a.onExitComplete;

  if (isExiting) {
    invariant(!!props.exit, "No exit animation defined.");
    return __assign({}, props, {
      /**
       * Overwrite user-defined `custom` with the one incoming from `AnimatePresence`.
       * This will only be defined when a component is exiting and it allows a user
       * to update `custom` even when a component has been removed from the tree.
       */
      custom: custom !== undefined ? custom : props.custom,
      // Animate to `exit` just by overwriting `animate`.
      animate: props.exit,
      onAnimationComplete: function onAnimationComplete() {
        onExitComplete && onExitComplete();
        props.onAnimationComplete && props.onAnimationComplete();
      }
    });
  } else if (initial === false) {
    return __assign({}, props, {
      initial: initial
    });
  }

  return props;
}

var checkShouldInheritVariant = function checkShouldInheritVariant(_a) {
  var animate = _a.animate,
      variants = _a.variants,
      _b = _a.inherit,
      inherit = _b === void 0 ? true : _b;
  return inherit && !!variants && (!animate || animate instanceof AnimationControls);
};
/**
 * @internal
 */


var createMotionComponent = function createMotionComponent(_a) {
  var getValueControlsConfig = _a.getValueControlsConfig,
      loadFunctionalityComponents = _a.loadFunctionalityComponents,
      renderComponent = _a.renderComponent;

  function MotionComponent(props, externalRef) {
    var ref = useExternalRef(externalRef);

    var _a = useContext(MotionContext),
        exitProps = _a.exitProps,
        parentContext = __rest(_a, ["exitProps"]);

    if (exitProps) {
      props = applyExitProps(props, exitProps);
    }

    var isStatic = parentContext["static"] || props["static"] || false;
    var values = useMotionValues(props);
    var style = useMotionStyles(values, props.style, isStatic, props.transformValues);
    var shouldInheritVariant = checkShouldInheritVariant(props);
    var controlsConfig = useConstant(function () {
      return getValueControlsConfig(ref, values);
    });
    var controls = useValueAnimationControls(controlsConfig, props, shouldInheritVariant);
    var context = useMotionContext(parentContext, controls, values, isStatic, props);
    var functionality = isStatic ? null : loadFunctionalityComponents(ref, values, props, controls, shouldInheritVariant);
    var renderedComponent = renderComponent(ref, style, values, props, isStatic);
    return createElement(Fragment, null, createElement(MountMotionValues, {
      ref: ref,
      values: values,
      isStatic: isStatic
    }), functionality, createElement(MotionContext.Provider, {
      value: context
    }, renderedComponent));
  }

  return forwardRef(MotionComponent);
};
/**
 * @internal
 */


var htmlElements = ["a", "abbr", "address", "area", "article", "aside", "audio", "b", "base", "bdi", "bdo", "big", "blockquote", "body", "br", "button", "canvas", "caption", "cite", "code", "col", "colgroup", "data", "datalist", "dd", "del", "details", "dfn", "dialog", "div", "dl", "dt", "em", "embed", "fieldset", "figcaption", "figure", "footer", "form", "h1", "h2", "h3", "h4", "h5", "h6", "head", "header", "hgroup", "hr", "html", "i", "iframe", "img", "input", "ins", "kbd", "keygen", "label", "legend", "li", "link", "main", "map", "mark", "menu", "menuitem", "meta", "meter", "nav", "noscript", "object", "ol", "optgroup", "option", "output", "p", "param", "picture", "pre", "progress", "q", "rp", "rt", "ruby", "s", "samp", "script", "section", "select", "small", "source", "span", "strong", "style", "sub", "summary", "sup", "table", "tbody", "td", "textarea", "tfoot", "th", "thead", "time", "title", "tr", "track", "u", "ul", "var", "video", "wbr", "webview"];
/**
 * @internal
 */

var svgElements = ["animate", "circle", "clipPath", "defs", "desc", "ellipse", "feBlend", "feColorMatrix", "feComponentTransfer", "feComposite", "feConvolveMatrix", "feDiffuseLighting", "feDisplacementMap", "feDistantLight", "feDropShadow", "feFlood", "feFuncA", "feFuncB", "feFuncG", "feFuncR", "feGaussianBlur", "feImage", "feMerge", "feMergeNode", "feMorphology", "feOffset", "fePointLight", "feSpecularLighting", "feSpotLight", "feTile", "feTurbulence", "filter", "foreignObject", "g", "image", "line", "linearGradient", "marker", "mask", "metadata", "path", "pattern", "polygon", "polyline", "radialGradient", "rect", "stop", "svg", "switch", "symbol", "text", "textPath", "tspan", "use", "view"];
/** @public */

var Point;

(function (Point) {
  /** @beta */
  Point.subtract = function (a, b) {
    return {
      x: a.x - b.x,
      y: a.y - b.y
    };
  };
  /** @beta */


  Point.relativeTo = function (idOrElem) {
    var elem;

    var getElem = function getElem() {
      // Caching element here could be leaky because of React lifecycle
      if (elem !== undefined) return elem;

      if (typeof idOrElem === "string") {
        elem = document.getElementById(idOrElem);
      } else {
        elem = idOrElem;
      }

      return elem;
    };

    return function (_a) {
      var x = _a.x,
          y = _a.y;
      var localElem = getElem();
      if (!localElem) return undefined;
      var rect = localElem.getBoundingClientRect();
      return {
        x: x - rect.left - window.scrollX,
        y: y - rect.top - window.scrollY
      };
    };
  };
})(Point || (Point = {}));
/**
 * @internal
 */


var MotionPluginContext = createContext({
  transformPagePoint: function transformPagePoint(p) {
    return p;
  }
});
/**
 * @internal
 * @internalremarks For now I think this should remain a private API for our own use
 * until we can figure out a nicer way of allowing people to add these
 */

function MotionPlugins(_a) {
  var children = _a.children,
      props = __rest(_a, ["children"]);

  var pluginContext = useContext(MotionPluginContext);
  var value = useRef(__assign({}, pluginContext)).current; // Mutative to prevent triggering rerenders in all listening
  // components every time this component renders

  for (var key in props) {
    value[key] = props[key];
  }

  return createElement(MotionPluginContext.Provider, {
    value: value
  }, children);
}

var isViewportScrollBlocked = false;
var isBrowser = typeof window !== "undefined";

if (isBrowser) {
  window.addEventListener("touchmove", function (event) {
    if (isViewportScrollBlocked) {
      event.preventDefault();
    }
  }, {
    passive: false
  });
}

var blockViewportScroll = function blockViewportScroll() {
  return isViewportScrollBlocked = true;
};

var unblockViewportScroll = function unblockViewportScroll() {
  return isViewportScrollBlocked = false;
};

function isMouseEvent(event) {
  // PointerEvent inherits from MouseEvent so we can't use a straight instanceof check.
  if (typeof PointerEvent !== "undefined" && event instanceof PointerEvent) {
    return !!(event.pointerType === "mouse");
  }

  return event instanceof MouseEvent;
}

function isTouchEvent(event) {
  var hasTouches = !!event.touches;
  return hasTouches;
}

function useUnmountEffect(callback) {
  return useEffect(function () {
    return function () {
      return callback();
    };
  }, []);
}

function addDomEvent(target, eventName, handler, options) {
  if (!handler) return;
  target.addEventListener(eventName, handler, options);
  return function () {
    return target.removeEventListener(eventName, handler, options);
  };
}
/**
 * Attaches an event listener directly to the provided DOM element.
 *
 * Bypassing React's event system can be desirable, for instance when attaching non-passive
 * event handlers.
 *
 * ```jsx
 * const ref = useRef(null)
 *
 * useDomEvent(ref, 'wheel', onWheel, { passive: false })
 *
 * return <div ref={ref} />
 * ```
 *
 * @param ref - React.RefObject that's been provided to the element you want to bind the listener to.
 * @param eventName - Name of the event you want listen for.
 * @param handler - Function to fire when receiving the event.
 * @param options - Options to pass to `Event.addEventListener`.
 *
 * @public
 */


function useDomEvent(ref, eventName, handler, options) {
  useEffect(function () {
    var element = ref.current;

    if (handler && element) {
      return addDomEvent(element, eventName, handler, options);
    }
  }, [ref, eventName, handler, options]);
}
/**
 * Filters out events not attached to the primary pointer (currently left mouse button)
 * @param eventHandler
 */


function filterPrimaryPointer(eventHandler) {
  if (!eventHandler) return undefined;
  return function (event) {
    var isMouseEvent = event instanceof MouseEvent;
    var isPrimaryPointer = !isMouseEvent || isMouseEvent && event.button === 0;

    if (isPrimaryPointer) {
      eventHandler(event);
    }
  };
}

var defaultPagePoint = {
  pageX: 0,
  pageY: 0
};

function pointFromTouch(e) {
  var primaryTouch = e.touches[0] || e.changedTouches[0];

  var _a = primaryTouch || defaultPagePoint,
      pageX = _a.pageX,
      pageY = _a.pageY;

  return {
    x: pageX,
    y: pageY
  };
}

function pointFromMouse(_a) {
  var _b = _a.pageX,
      pageX = _b === void 0 ? 0 : _b,
      _c = _a.pageY,
      pageY = _c === void 0 ? 0 : _c;
  return {
    x: pageX,
    y: pageY
  };
}

function extractEventInfo(event) {
  return {
    point: isTouchEvent(event) ? pointFromTouch(event) : pointFromMouse(event)
  };
}

var wrapHandler = function wrapHandler(handler, shouldFilterPrimaryPointer) {
  if (shouldFilterPrimaryPointer === void 0) {
    shouldFilterPrimaryPointer = false;
  }

  if (!handler) return;

  var listener = function listener(event) {
    return handler(event, extractEventInfo(event));
  };

  return shouldFilterPrimaryPointer ? filterPrimaryPointer(listener) : listener;
}; // We check for event support via functions in case they've been mocked by a testing suite.


var isBrowser$1 = typeof window !== "undefined";

var supportsPointerEvents = function supportsPointerEvents() {
  return isBrowser$1 && window.onpointerdown === null;
};

var supportsTouchEvents = function supportsTouchEvents() {
  return isBrowser$1 && window.ontouchstart === null;
};

var supportsMouseEvents = function supportsMouseEvents() {
  return isBrowser$1 && window.onmousedown === null;
};

var mouseEventNames = {
  pointerdown: "mousedown",
  pointermove: "mousemove",
  pointerup: "mouseup",
  pointercancel: "mousecancel",
  pointerover: "mouseover",
  pointerout: "mouseout",
  pointerenter: "mouseenter",
  pointerleave: "mouseleave"
};
var touchEventNames = {
  pointerdown: "touchstart",
  pointermove: "touchmove",
  pointerup: "touchend",
  pointercancel: "touchcancel"
};

function getPointerEventName(name) {
  if (supportsPointerEvents()) {
    return name;
  } else if (supportsTouchEvents()) {
    return touchEventNames[name];
  } else if (supportsMouseEvents()) {
    return mouseEventNames[name];
  }

  return name;
}

function addPointerEvent(target, eventName, handler, options) {
  return addDomEvent(target, getPointerEventName(eventName), wrapHandler(handler, eventName === "pointerdown"), options);
}

function usePointerEvent(ref, eventName, handler, options) {
  return useDomEvent(ref, getPointerEventName(eventName), wrapHandler(handler, eventName === "pointerdown"), options);
}

function startDevicePoint(session) {
  return session.pointHistory[0];
}

function lastDevicePoint(session) {
  return session.pointHistory[session.pointHistory.length - 1];
}

function getVelocity$1(session, timeDelta) {
  var pointHistory = session.pointHistory;

  if (pointHistory.length < 2) {
    return {
      x: 0,
      y: 0
    };
  }

  var i = pointHistory.length - 1;
  var timestampedPoint = null;
  var lastPoint = lastDevicePoint(session);

  while (i >= 0) {
    timestampedPoint = pointHistory[i];

    if (lastPoint.timestamp - timestampedPoint.timestamp > secondsToMilliseconds(timeDelta)) {
      break;
    }

    i--;
  }

  if (!timestampedPoint) {
    return {
      x: 0,
      y: 0
    };
  }

  var time = (lastPoint.timestamp - timestampedPoint.timestamp) / 1000;

  if (time === 0) {
    return {
      x: 0,
      y: 0
    };
  }

  var currentVelocity = {
    x: (lastPoint.x - timestampedPoint.x) / time,
    y: (lastPoint.y - timestampedPoint.y) / time
  };

  if (currentVelocity.x === Infinity) {
    currentVelocity.x = 0;
  }

  if (currentVelocity.y === Infinity) {
    currentVelocity.y = 0;
  }

  return currentVelocity;
}
/**
 *
 * @param handlers -
 * @param ref -
 *
 * @internalremarks
 * Currently this sets new pan gesture functions every render. The memo route has been explored
 * in the past but ultimately we're still creating new functions every render. An optimisation
 * to explore is creating the pan gestures and loading them into a `ref`.
 *
 * @internal
 */


function usePanGesture(_a, ref) {
  var onPan = _a.onPan,
      onPanStart = _a.onPanStart,
      onPanEnd = _a.onPanEnd,
      onPanSessionStart = _a.onPanSessionStart;
  var hasPanEvents = onPan || onPanStart || onPanEnd || onPanSessionStart;
  var session = useRef(null);
  var lastMoveEvent = useRef(null);
  var lastMoveEventInfo = useRef(null);
  var transformPagePoint = useContext(MotionPluginContext).transformPagePoint;
  var pointerEventSubscription = useRef(null); // Load the callbacks into mutable state to ensure that even if we don't create a new
  // gesture handler every render, we still reference the latest callbacks (which are almost certain to change per render)

  var handlers = useRef({}).current;
  handlers.onPanSessionStart = onPanSessionStart;
  handlers.onPanStart = onPanStart;
  handlers.onPan = onPan;
  handlers.onPanEnd = onPanEnd;

  function removePointerEvents() {
    pointerEventSubscription.current && pointerEventSubscription.current();
    pointerEventSubscription.current = null;
  }

  function getPanInfo(_a) {
    var point = _a.point;
    var currentPoint = session.current; // TODO: A potential optimisation here that might be a breaking change would be
    // to keep one mutable ref to a point that we update

    return {
      point: point,
      delta: Point.subtract(point, lastDevicePoint(currentPoint)),
      offset: Point.subtract(point, startDevicePoint(currentPoint)),
      velocity: getVelocity$1(currentPoint, 0.1)
    };
  }

  function transformPoint(info) {
    return {
      point: transformPagePoint(info.point)
    };
  }

  function cancelPan() {
    removePointerEvents();
    cancelSync.update(updatePoint);
    unblockViewportScroll();
  }

  function updatePoint() {
    if (!session.current || !lastMoveEvent.current || !lastMoveEventInfo.current) {
      warning(false, "onPointerMove fired without pointer session");
      cancelPan();
      return;
    }

    var info = getPanInfo(lastMoveEventInfo.current);
    var panStarted = session.current.startEvent !== undefined; // Only start panning if the offset is larger than 3 pixels. If we make it
    // any larger than this we'll want to reset the pointer history
    // on the first update to avoid visual snapping to the cursoe.

    var distancePastThreshold = distance(info.offset, {
      x: 0,
      y: 0
    }) >= 3;
    if (!panStarted && !distancePastThreshold) return;
    var point = info.point;
    var timestamp = getFrameData().timestamp;
    session.current.pointHistory.push(__assign({}, point, {
      timestamp: timestamp
    }));

    if (!panStarted) {
      handlers.onPanStart && handlers.onPanStart(lastMoveEvent.current, info);
      session.current.startEvent = lastMoveEvent.current;
    }

    handlers.onPan && handlers.onPan(lastMoveEvent.current, info);
  }

  function onPointerMove(event, info) {
    lastMoveEvent.current = event;
    lastMoveEventInfo.current = transformPoint(info); // Because Safari doesn't trigger mouseup events when it's above a `<select>`

    if (isMouseEvent(event) && event.buttons === 0) {
      onPointerUp(event, info);
      return;
    } // Throttle mouse move event to once per frame


    sync.update(updatePoint, true);
  }

  function onPointerUp(event, info) {
    cancelPan();

    if (!session.current) {
      warning(false, "onPointerUp fired without pointer session");
      return;
    }

    handlers.onPanEnd && handlers.onPanEnd(event, getPanInfo(transformPoint(info)));
    session.current = null;
  }

  function onPointerDown(event, info) {
    // If we have more than one touch, don't start detecting this gesture
    if (isTouchEvent(event) && event.touches.length > 1) return;
    var initialInfo = transformPoint(info);
    var point = initialInfo.point;
    var timestamp = getFrameData().timestamp;
    session.current = {
      target: event.target,
      pointHistory: [__assign({}, point, {
        timestamp: timestamp
      })]
    };
    handlers.onPanSessionStart && handlers.onPanSessionStart(event, getPanInfo(initialInfo));
    removePointerEvents();
    var removeOnPointerMove = addPointerEvent(window, "pointermove", onPointerMove);
    var removeOnPointerUp = addPointerEvent(window, "pointerup", onPointerUp);

    pointerEventSubscription.current = function () {
      removeOnPointerMove && removeOnPointerMove();
      removeOnPointerUp && removeOnPointerUp();
    };
  }

  usePointerEvent(ref, "pointerdown", hasPanEvents && onPointerDown);
  useUnmountEffect(cancelPan);
}
/**
 * Recursively traverse up the tree to check whether the provided child node
 * is the parent or a descendant of it.
 *
 * @param parent - Element to find
 * @param child - Element to test against parent
 */


var isNodeOrChild = function isNodeOrChild(parent, child) {
  if (!child) {
    return false;
  } else if (parent === child) {
    return true;
  } else {
    return isNodeOrChild(parent, child.parentElement);
  }
};

var order = ["whileHover", "whileTap", "whileDrag"];

var getGesturePriority = function getGesturePriority(gesture) {
  return order.indexOf(gesture) + 1;
};

function createLock(name) {
  var lock = null;
  return function () {
    var openLock = function openLock() {
      lock = null;
    };

    if (lock === null) {
      lock = name;
      return openLock;
    }

    return false;
  };
}

var globalHorizontalLock = createLock("dragHorizontal");
var globalVerticalLock = createLock("dragVertical");

function getGlobalLock(drag) {
  var lock = false;

  if (drag === "y") {
    lock = globalVerticalLock();
  } else if (drag === "x") {
    lock = globalHorizontalLock();
  } else {
    var openHorizontal_1 = globalHorizontalLock();
    var openVertical_1 = globalVerticalLock();

    if (openHorizontal_1 && openVertical_1) {
      lock = function lock() {
        openHorizontal_1();
        openVertical_1();
      };
    } else {
      // Release the locks because we don't use them
      if (openHorizontal_1) openHorizontal_1();
      if (openVertical_1) openVertical_1();
    }
  }

  return lock;
}

var tapGesturePriority = getGesturePriority("whileTap");
/**
 * @param handlers -
 * @internal
 */

function useTapGesture(_a, ref) {
  var onTap = _a.onTap,
      onTapStart = _a.onTapStart,
      onTapCancel = _a.onTapCancel,
      whileTap = _a.whileTap,
      controls = _a.controls;
  var hasTapListeners = onTap || onTapStart || onTapCancel || whileTap;
  var isTapping = useRef(false);
  var cancelPointerEventListener = useRef(null);

  function removePointerUp() {
    cancelPointerEventListener.current && cancelPointerEventListener.current();
    cancelPointerEventListener.current = null;
  }

  if (whileTap && controls) {
    controls.setOverride(whileTap, tapGesturePriority);
  } // We load this event handler into a ref so we can later refer to
  // onPointerUp.current which will always have reference to the latest props


  var onPointerUp = useRef(null);

  onPointerUp.current = function (event, info) {
    var element = ref.current;
    removePointerUp();
    if (!isTapping.current || !element) return;
    isTapping.current = false;

    if (controls && whileTap) {
      controls.clearOverride(tapGesturePriority);
    } // Check the gesture lock - if we get it, it means no drag gesture is active
    // and we can safely fire the tap gesture.


    var openGestureLock = getGlobalLock(true);
    if (!openGestureLock) return;
    openGestureLock();

    if (!isNodeOrChild(element, event.target)) {
      onTapCancel && onTapCancel(event, info);
    } else {
      onTap && onTap(event, info);
    }
  };

  function onPointerDown(event, info) {
    removePointerUp();
    cancelPointerEventListener.current = addPointerEvent(window, "pointerup", function (event, info) {
      return onPointerUp.current(event, info);
    });
    var element = ref.current;
    if (!element || isTapping.current) return;
    isTapping.current = true;
    onTapStart && onTapStart(event, info);

    if (controls && whileTap) {
      controls.startOverride(tapGesturePriority);
    }
  }

  usePointerEvent(ref, "pointerdown", hasTapListeners ? onPointerDown : undefined);
  useUnmountEffect(removePointerUp);
}

var hoverPriority = getGesturePriority("whileHover");

var filterTouch = function filterTouch(listener) {
  return function (event, info) {
    if (isMouseEvent(event)) listener(event, info);
  };
};
/**
 *
 * @param props
 * @param ref
 * @internal
 */


function useHoverGesture(_a, ref) {
  var whileHover = _a.whileHover,
      onHoverStart = _a.onHoverStart,
      onHoverEnd = _a.onHoverEnd,
      controls = _a.controls;

  if (whileHover && controls) {
    controls.setOverride(whileHover, hoverPriority);
  }

  usePointerEvent(ref, "pointerenter", filterTouch(function (event, info) {
    if (onHoverStart) onHoverStart(event, info);

    if (whileHover && controls) {
      controls.startOverride(hoverPriority);
    }
  }));
  usePointerEvent(ref, "pointerleave", filterTouch(function (event, info) {
    if (onHoverEnd) onHoverEnd(event, info);

    if (whileHover && controls) {
      controls.clearOverride(hoverPriority);
    }
  }));
}
/**
 * Add pan and tap gesture recognition to an element.
 *
 * @param props - Gesture event handlers
 * @param ref - React `ref` containing a DOM `Element`
 * @public
 */


function useGestures(props, ref) {
  usePanGesture(props, ref);
  useTapGesture(props, ref);
  useHoverGesture(props, ref);
}

var makeHookComponent = function makeHookComponent(hook) {
  return function (props) {
    hook(props);
    return null;
  };
};

var gestureProps = ["drag", "onPan", "onPanStart", "onPanEnd", "onPanSessionStart", "onTap", "onTapStart", "onTapCancel", "whileTap", "whileHover", "onHoverStart", "onHoverEnd"];
var Gestures = {
  key: "gestures",
  shouldRender: function shouldRender(props) {
    return gestureProps.some(function (key) {
      return props.hasOwnProperty(key);
    });
  },
  Component: makeHookComponent(function (_a) {
    var innerRef = _a.innerRef,
        props = __rest(_a, ["innerRef"]);

    useGestures(props, innerRef);
  })
};

var isRefObject = function isRefObject(ref) {
  return typeof ref === "object" && ref.hasOwnProperty("current");
};
/**
 * A hook to use the window resize listener. In future it might be cool to use `ResizeObserver`
 * but it currently needs to be polyfilled and the size trade-off isn't there for this use-case.
 *
 * TODO: Debounce this bad boy
 *
 * @param onResize
 */


function useResize(element, onResize) {
  useEffect(function () {
    if (!element || !isRefObject(element)) return;
    window.addEventListener("resize", onResize);
    return function () {
      return window.removeEventListener("resize", onResize);
    };
  }, [element, onResize]);
}
/**
 * Creates a `MotionValue` to track the state and velocity of a value.
 *
 * Usually, these are created automatically. For advanced use-cases, like use with `useTransform`, you can create `MotionValue`s externally and pass them into the animated component via the `style` prop.
 *
 * @library
 *
 * ```jsx
 * export function MyComponent() {
 *   const scale = useMotionValue(1)
 *
 *   return <Frame scale={scale} />
 * }
 * ```
 *
 * @motion
 *
 * ```jsx
 * export const MyComponent = () => {
 *   const scale = useMotionValue(1)
 *
 *   return <motion.div style={{ scale }} />
 * }
 * ```
 *
 * @param initial - The initial state.
 *
 * @public
 */


function useMotionValue(initial) {
  return useConstant(function () {
    return motionValue(initial);
  });
}
/**
 * Don't block the default pointerdown behaviour of these elements.
 */


var allowDefaultPointerDown = new Set(["INPUT", "TEXTAREA", "SELECT"]);

var getBoundingBox = function getBoundingBox(ref, transformPagePoint) {
  var rect = ref.current.getBoundingClientRect();

  var _a = transformPagePoint({
    x: rect.left,
    y: rect.top
  }),
      left = _a.x,
      top = _a.y;

  var _b = transformPagePoint({
    x: rect.width,
    y: rect.height
  }),
      width = _b.x,
      height = _b.y;

  return {
    left: left,
    top: top,
    width: width,
    height: height
  };
};

var getCurrentOffset = function getCurrentOffset(point) {
  return point ? point.get() : 0;
};
/**
 * Takes a parent Element and a draggable Element and returns pixel-based drag constraints.
 *
 * @param constraintsRef
 * @param draggableRef
 */


var calculateConstraintsFromDom = function calculateConstraintsFromDom(constraintsRef, draggableRef, point, transformPagePoint) {
  invariant(constraintsRef.current !== null && draggableRef.current !== null, "If `dragConstraints` is set as a React ref, that ref must be passed to another component's `ref` prop.");
  var parentBoundingBox = getBoundingBox(constraintsRef, transformPagePoint);
  var draggableBoundingBox = getBoundingBox(draggableRef, transformPagePoint);
  var left = parentBoundingBox.left - draggableBoundingBox.left + getCurrentOffset(point.x);
  var top = parentBoundingBox.top - draggableBoundingBox.top + getCurrentOffset(point.y);
  var right = parentBoundingBox.width - draggableBoundingBox.width + left;
  var bottom = parentBoundingBox.height - draggableBoundingBox.height + top;
  return {
    top: top,
    left: left,
    right: right,
    bottom: bottom
  };
};

function shouldDrag(direction, drag, currentDirection) {
  return (drag === true || drag === direction) && (currentDirection === null || currentDirection === direction);
}

var getConstraints = function getConstraints(axis, _a) {
  var top = _a.top,
      right = _a.right,
      bottom = _a.bottom,
      left = _a.left;

  if (axis === "x") {
    return {
      min: left,
      max: right
    };
  } else {
    return {
      min: top,
      max: bottom
    };
  }
};

function applyConstraints(axis, value, constraints, dragElastic) {
  var constrainedValue = value instanceof MotionValue ? value.get() : value;

  if (!constraints) {
    return constrainedValue;
  }

  var _a = getConstraints(axis, constraints),
      min = _a.min,
      max = _a.max;

  if (min !== undefined && constrainedValue < min) {
    constrainedValue = dragElastic ? applyOverdrag(min, constrainedValue, dragElastic) : Math.max(min, constrainedValue);
  } else if (max !== undefined && constrainedValue > max) {
    constrainedValue = dragElastic ? applyOverdrag(max, constrainedValue, dragElastic) : Math.min(max, constrainedValue);
  }

  if (value instanceof MotionValue) {
    value.set(constrainedValue);
  }

  return constrainedValue;
}

function applyOverdrag(origin, current, dragElastic) {
  var dragFactor = typeof dragElastic === "number" ? dragElastic : 0.35;
  return mix(origin, current, dragFactor);
}

function bothAxis(handler) {
  return [handler("x"), handler("y")];
}
/**
 * A hook that allows an element to be dragged.
 *
 * @param param
 * @param ref
 * @param values
 * @param controls
 *
 * @internal
 */


function useDrag(_a, ref, values, controls) {
  var _b = _a.drag,
      drag = _b === void 0 ? false : _b,
      _c = _a.dragDirectionLock,
      dragDirectionLock = _c === void 0 ? false : _c,
      _d = _a.dragPropagation,
      dragPropagation = _d === void 0 ? false : _d,
      _e = _a.dragConstraints,
      dragConstraints = _e === void 0 ? false : _e,
      _f = _a.dragElastic,
      dragElastic = _f === void 0 ? true : _f,
      _g = _a.dragMomentum,
      dragMomentum = _g === void 0 ? true : _g,
      _dragValueX = _a._dragValueX,
      _dragValueY = _a._dragValueY,
      _dragTransitionControls = _a._dragTransitionControls,
      dragOriginX = _a.dragOriginX,
      dragOriginY = _a.dragOriginY,
      dragTransition = _a.dragTransition,
      onDirectionLock = _a.onDirectionLock,
      onDragStart = _a.onDragStart,
      onDrag = _a.onDrag,
      onDragEnd = _a.onDragEnd,
      onDragTransitionEnd = _a.onDragTransitionEnd; // If `dragConstraints` is a React `ref`, we should resolve the constraints once the
  // component has rendered.

  var constraintsNeedResolution = isRefObject(dragConstraints); // We create a mutable state using a ref as we want to keep track of certain data, even across renders,
  // but we don't want to re-render as a result of them.

  var dragStatus = useRef({
    isDragging: false,
    currentDirection: null,
    constraints: false,
    handlers: {}
  }).current; // Load the callbacks into mutable state to ensure that even if we don't create a new
  // gesture handler every render, we still reference the latest callbacks (which are almost certain to change per render)

  var handlers = dragStatus.handlers;
  handlers.onDragStart = onDragStart;
  handlers.onDrag = onDrag;
  handlers.onDragEnd = onDragEnd;
  handlers.onDirectionLock = onDirectionLock;
  handlers.onDragTransitionEnd = onDragTransitionEnd;
  var point = useRef({}).current; // Track origin

  var defaultOriginX = useMotionValue(0);
  var defaultOriginY = useMotionValue(0);
  var origin = {
    x: dragOriginX || defaultOriginX,
    y: dragOriginY || defaultOriginY
  }; // This is a reference to the global drag gesture lock, ensuring only one component
  // can "capture" the drag of one or both axes.

  var openGlobalLock = useRef(null);
  var transformPagePoint = useContext(MotionPluginContext).transformPagePoint; // If `dragConstraints` is a React `ref`, we need to track changes in its
  // size and update the current draggable position relative to that.

  var prevConstraintsBox = useRef({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  }).current;

  var scalePoint = function scalePoint() {
    if (!isRefObject(dragConstraints)) return;
    var constraintsBox = getBoundingBox(dragConstraints, transformPagePoint);
    var draggableBox = getBoundingBox(ref, transformPagePoint); // Scale a point relative to the transformation of a constraints-providing element.

    var scaleAxisPoint = function scaleAxisPoint(axis, dimension) {
      var pointToScale = point[axis];
      if (!pointToScale) return; // Stop any current animations as they bug out if you resize during one

      if (pointToScale.isAnimating()) {
        pointToScale.stop();
        recordBoxInfo();
        return;
      } // If the previous dimension was `0` (default), set `scale` to `1` to prevent
      // divide by zero errors.


      var scale = prevConstraintsBox[dimension] ? (constraintsBox[dimension] - draggableBox[dimension]) / prevConstraintsBox[dimension] : 1;
      pointToScale.set(prevConstraintsBox[axis] * scale);
    };

    scaleAxisPoint("x", "width");
    scaleAxisPoint("y", "height");
  };

  useResize(dragConstraints, scalePoint); // If our drag constraints are a potentially live bounding box, record its previously-calculated
  // dimensions and the current x/y

  var recordBoxInfo = function recordBoxInfo(constraints) {
    if (constraints) {
      var right = constraints.right,
          left = constraints.left,
          bottom = constraints.bottom,
          top_1 = constraints.top;
      prevConstraintsBox.width = (right || 0) - (left || 0);
      prevConstraintsBox.height = (bottom || 0) - (top_1 || 0);
    }

    if (point.x) prevConstraintsBox.x = point.x.get();
    if (point.y) prevConstraintsBox.y = point.y.get();
  };

  var applyConstraintsToPoint = function applyConstraintsToPoint(constraints) {
    return bothAxis(function (axis) {
      var axisPoint = point[axis];
      axisPoint && !axisPoint.isAnimating() && applyConstraints(axis, axisPoint, constraints, 0);
    });
  }; // On mount, if our bounding box is a ref, we need to resolve the constraints
  // and immediately apply them to our point.


  useEffect(function () {
    if (!constraintsNeedResolution) return;
    var constraints = calculateConstraintsFromDom(dragConstraints, ref, point, transformPagePoint);
    applyConstraintsToPoint(constraints);
    recordBoxInfo(constraints);
  }, []); // If `dragConstraints` is set to `false` or `Constraints`, set constraints immediately.
  // Otherwise we'll resolve on mount.

  dragStatus.constraints = constraintsNeedResolution ? dragStatus.constraints || false : dragConstraints; // Get the `MotionValue` for both draggable axes, or create them if they don't already
  // exist on this component.

  bothAxis(function (axis) {
    if (!shouldDrag(axis, drag, dragStatus.currentDirection)) return;
    var defaultValue = axis === "x" ? _dragValueX : _dragValueY;
    point[axis] = defaultValue || values.get(axis, 0);
  }); // Apply constraints immediately, even before render, if our constraints are a plain object.

  if (!dragStatus.isDragging && dragStatus.constraints && !constraintsNeedResolution) {
    applyConstraintsToPoint(dragStatus.constraints);
  } // Add additional information to the `PanInfo` object before passing it to drag listeners.


  function convertPanToDrag(info) {
    return __assign({}, info, {
      point: {
        x: point.x ? point.x.get() : 0,
        y: point.y ? point.y.get() : 0
      }
    });
  } // This function will be used to update each axis point every frame.


  function updatePoint(axis, offset) {
    var axisPoint = point[axis]; // If we're not dragging this axis, do an early return.

    if (!shouldDrag(axis, drag, dragStatus.currentDirection) || !axisPoint) {
      return;
    }

    var current = applyConstraints(axis, origin[axis].get() + offset[axis], dragStatus.constraints, dragElastic);
    axisPoint.set(current);
  }

  function onPanSessionStart(event) {
    // Prevent browser-specific behaviours like text selection or Chrome's image dragging.
    if (event.target && !allowDefaultPointerDown.has(event.target.tagName)) {
      event.preventDefault(); // Make sure input elements loose focus when we prevent the default.

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    } // Initiate viewport scroll blocking on touch start. This is a very aggressive approach
    // which has come out of the difficulty in us being able to do this once a scroll gesture
    // has initiated in mobile browsers. This means if there's a horizontally-scrolling carousel
    // on a page we can't let a user scroll the page itself from it. Ideally what we'd do is
    // trigger this once we've got a scroll direction determined. This approach sort-of worked
    // but if the component was dragged too far in a single frame page scrolling would initiate.


    blockViewportScroll(); // Stop any animations on both axis values immediately. This allows the user to throw and catch
    // the component.

    bothAxis(function (axis) {
      var axisPoint = point[axis];
      axisPoint && axisPoint.stop();
    });
  }

  function onPanStart(event, info) {
    dragStatus.isDragging = true; // Resolve the constraints again in case anything has changed in the meantime.

    if (constraintsNeedResolution) {
      dragStatus.constraints = calculateConstraintsFromDom(dragConstraints, ref, point, transformPagePoint);
      applyConstraintsToPoint(dragStatus.constraints);
    } // Set point origin and stop any existing animations.


    bothAxis(function (axis) {
      var axisPoint = point[axis];
      if (!axisPoint) return;
      origin[axis].set(axisPoint.get());
      axisPoint.stop();
    }); // Attempt to grab the global drag gesture lock.

    if (!dragPropagation) {
      if (openGlobalLock.current) openGlobalLock.current();
      openGlobalLock.current = getGlobalLock(drag);

      if (!openGlobalLock.current) {
        return;
      }
    }

    dragStatus.currentDirection = null;
    var onDragStart = handlers.onDragStart;
    onDragStart && onDragStart(event, convertPanToDrag(info));
  }

  function onPan(event, info) {
    // If we didn't successfully receive the gesture lock, early return.
    if (!dragPropagation && !openGlobalLock.current) {
      return;
    }

    var offset = info.offset; // Attempt to detect drag direction if directionLock is true

    if (dragDirectionLock && dragStatus.currentDirection === null) {
      dragStatus.currentDirection = getCurrentDirection(offset); // If we've successfully set a direction, notify listener

      if (dragStatus.currentDirection !== null) {
        var onDirectionLock_1 = handlers.onDirectionLock;
        onDirectionLock_1 && onDirectionLock_1(dragStatus.currentDirection);
      }

      return;
    }

    updatePoint("x", offset);
    updatePoint("y", offset);
    var onDrag = handlers.onDrag;
    onDrag && onDrag(event, convertPanToDrag(info));
  }

  function cancelDrag() {
    unblockViewportScroll();
    dragStatus.isDragging = false;

    if (!dragPropagation && openGlobalLock.current) {
      openGlobalLock.current();
      openGlobalLock.current = null;
    }
  }

  function animateDragEnd(velocity) {
    var momentumAnimations = bothAxis(function (axis) {
      var _a;

      if (!shouldDrag(axis, drag, dragStatus.currentDirection)) {
        return;
      }

      var transition = dragStatus.constraints ? getConstraints(axis, dragStatus.constraints) : {};
      /**
       * Overdamp the boundary spring if `dragElastic` is disabled. There's still a frame
       * of spring animations so we should look into adding a disable spring option to `inertia`.
       * We could do something here where we affect the `bounceStiffness` and `bounceDamping`
       * using the value of `dragElastic`.
       */

      var bounceStiffness = dragElastic ? 200 : 1000000;
      var bounceDamping = dragElastic ? 40 : 10000000;
      var animationControls = _dragTransitionControls || controls;
      return animationControls.start((_a = {}, _a[axis] = 0, // TODO: It might be possible to allow `type` animations to be set as
      // Popmotion animations as well as strings. Then people could define their own
      // and it'd open another route for us to code-split.
      _a.transition = __assign({
        type: "inertia",
        velocity: dragMomentum ? velocity[axis] : 0,
        bounceStiffness: bounceStiffness,
        bounceDamping: bounceDamping,
        timeConstant: 750,
        restDelta: 1
      }, dragTransition, transition), _a));
    }); // Run all animations and then resolve the new drag constraints.

    Promise.all(momentumAnimations).then(function () {
      recordBoxInfo(dragStatus.constraints);
      scalePoint();
      var onDragTransitionEnd = handlers.onDragTransitionEnd;
      onDragTransitionEnd && onDragTransitionEnd();
    });
  }

  function onPanEnd(event, info) {
    var isDragging = dragStatus.isDragging;
    cancelDrag();
    if (!isDragging) return; // If we have either `dragMomentum` or `dragElastic`, initiate momentum and boundary spring animation for both axes.

    if (dragMomentum || dragElastic) {
      var velocity = info.velocity;
      animateDragEnd(velocity);
    } else {
      recordBoxInfo(dragStatus.constraints);
    }

    var onDragEnd = handlers.onDragEnd;
    onDragEnd && onDragEnd(event, convertPanToDrag(info));
  }

  usePanGesture(drag ? {
    onPan: onPan,
    onPanStart: onPanStart,
    onPanEnd: onPanEnd,
    onPanSessionStart: onPanSessionStart
  } : {}, ref);
  useUnmountEffect(function () {
    return dragStatus.isDragging && cancelDrag();
  });
}
/**
 * Based on an x/y offset determine the current drag direction. If both axis' offsets are lower
 * than the provided threshold, return `null`.
 *
 * @param offset - The x/y offset from origin.
 * @param lockThreshold - (Optional) - the minimum absolute offset before we can determine a drag direction.
 */


function getCurrentDirection(offset, lockThreshold) {
  if (lockThreshold === void 0) {
    lockThreshold = 10;
  }

  var direction = null;

  if (Math.abs(offset.y) > lockThreshold) {
    direction = "y";
  } else if (Math.abs(offset.x) > lockThreshold) {
    direction = "x";
  }

  return direction;
}

var Drag = {
  key: "drag",
  shouldRender: function shouldRender(props) {
    return !!props.drag;
  },
  Component: makeHookComponent(function (_a) {
    var innerRef = _a.innerRef,
        values = _a.values,
        controls = _a.controls,
        props = __rest(_a, ["innerRef", "values", "controls"]);

    return useDrag(props, innerRef, values, controls);
  })
};

function isCSSVariable(value) {
  return typeof value === "string" && value.startsWith("var(--");
}
/**
 * Parse Framer's special CSS variable format into a CSS token and a fallback.
 *
 * ```
 * `var(--foo, #fff)` => [`--foo`, '#fff']
 * ```
 *
 * @param current
 */


var cssVariableRegex = /var\((--[a-zA-Z0-9-_]+),? ?([a-zA-Z0-9 ()%#.,-]+)?\)/;

function parseCSSVariable(current) {
  var match = cssVariableRegex.exec(current);
  if (!match) return [,];
  var token = match[1],
      fallback = match[2];
  return [token, fallback];
}

var maxDepth = 4;

function getVariableValue(current, element, depth) {
  if (depth === void 0) {
    depth = 1;
  }

  invariant(depth <= maxDepth, "Max CSS variable fallback depth detected in property \"" + current + "\". This may indicate a circular fallback dependency.");

  var _a = parseCSSVariable(current),
      token = _a[0],
      fallback = _a[1]; // No CSS variable detected


  if (!token) return; // Attempt to read this CSS variable off the element

  var resolved = window.getComputedStyle(element).getPropertyValue(token);

  if (resolved) {
    return resolved;
  } else if (isCSSVariable(fallback)) {
    // The fallback might itself be a CSS variable, in which case we attempt to resolve it too.
    return getVariableValue(fallback, element, depth + 1);
  } else {
    return fallback;
  }
}
/**
 * Resolve CSS variables from
 *
 * @internal
 */


function resolveCSSVariables(values, ref, _a, transitionEnd) {
  var target = __rest(_a, []);

  var element = ref.current;
  if (!(element instanceof HTMLElement)) return {
    target: target,
    transitionEnd: transitionEnd
  }; // If `transitionEnd` isn't `undefined`, clone it. We could clone `target` and `transitionEnd`
  // only if they change but I think this reads clearer and this isn't a performance-critical path.

  if (transitionEnd) {
    transitionEnd = __assign({}, transitionEnd);
  } // Go through existing `MotionValue`s and ensure any existing CSS variables are resolved


  values.forEach(function (value) {
    var current = value.get();
    if (!isCSSVariable(current)) return;
    var resolved = getVariableValue(current, element);
    if (resolved) value.set(resolved);
  }); // Cycle through every target property and resolve CSS variables. Currently
  // we only read single-var properties like `var(--foo)`, not `calc(var(--foo) + 20px)`

  for (var key in target) {
    var current = target[key];
    if (!isCSSVariable(current)) continue;
    var resolved = getVariableValue(current, element);
    if (!resolved) continue; // Clone target if it hasn't already been

    target[key] = resolved; // If the user hasn't already set this key on `transitionEnd`, set it to the unresolved
    // CSS variable. This will ensure that after the animation the component will reflect
    // changes in the value of the CSS variable.

    if (transitionEnd && transitionEnd[key] === undefined) {
      transitionEnd[key] = current;
    }
  }

  return {
    target: target,
    transitionEnd: transitionEnd
  };
}

var positionalKeys = new Set(["width", "height", "top", "left", "right", "bottom", "x", "y"]);

var isPositionalKey = function isPositionalKey(key) {
  return positionalKeys.has(key);
};

var hasPositionalKey = function hasPositionalKey(target) {
  return Object.keys(target).some(isPositionalKey);
};

var setAndResetVelocity = function setAndResetVelocity(value, to) {
  // Looks odd but setting it twice doesn't render, it'll just
  // set both prev and current to the latest value
  value.set(to, false);
  value.set(to);
};

var isNumOrPxType = function isNumOrPxType(v) {
  return v === number || v === px;
};

var BoundingBoxDimension;

(function (BoundingBoxDimension) {
  BoundingBoxDimension["width"] = "width";
  BoundingBoxDimension["height"] = "height";
  BoundingBoxDimension["left"] = "left";
  BoundingBoxDimension["right"] = "right";
  BoundingBoxDimension["top"] = "top";
  BoundingBoxDimension["bottom"] = "bottom";
})(BoundingBoxDimension || (BoundingBoxDimension = {}));

var getPosFromMatrix = function getPosFromMatrix(matrix, pos) {
  return parseFloat(matrix.split(", ")[pos]);
};

var getTranslateFromMatrix = function getTranslateFromMatrix(pos2, pos3) {
  return function (_bbox, _a) {
    var transform = _a.transform;
    if (transform === "none" || !transform) return 0;
    var matrix3d = transform.match(/^matrix3d\((.+)\)$/);

    if (matrix3d) {
      return getPosFromMatrix(matrix3d[1], pos3);
    } else {
      var matrix = transform.match(/^matrix\((.+)\)$/);
      return getPosFromMatrix(matrix[1], pos2);
    }
  };
};

var transformKeys = new Set(["x", "y", "z"]);
var nonTranslationalTransformKeys = transformProps.filter(function (key) {
  return !transformKeys.has(key);
});

function removeNonTranslationalTransform(values, elementStyler) {
  var removedTransforms = [];
  nonTranslationalTransformKeys.forEach(function (key) {
    var value = values.get(key);

    if (value !== undefined) {
      removedTransforms.push([key, value.get()]);
      value.set(key.startsWith("scale") ? 1 : 0);
    }
  }); // Apply changes to element before measurement

  if (removedTransforms.length) elementStyler.render();
  return removedTransforms;
}

var positionalValues = {
  // Dimensions
  width: function width(_a) {
    var width = _a.width;
    return width;
  },
  height: function height(_a) {
    var height = _a.height;
    return height;
  },
  top: function top(_bbox, _a) {
    var top = _a.top;
    return parseFloat(top);
  },
  left: function left(_bbox, _a) {
    var left = _a.left;
    return parseFloat(left);
  },
  bottom: function bottom(_a, _b) {
    var height = _a.height;
    var top = _b.top;
    return parseFloat(top) + height;
  },
  right: function right(_a, _b) {
    var width = _a.width;
    var left = _b.left;
    return parseFloat(left) + width;
  },
  // Transform
  x: getTranslateFromMatrix(4, 13),
  y: getTranslateFromMatrix(5, 14)
};

var convertChangedValueTypes = function convertChangedValueTypes(target, values, element, elementStyler, changedKeys) {
  var originBbox = element.getBoundingClientRect();
  var elementComputedStyle = getComputedStyle(element);
  var display = elementComputedStyle.display,
      top = elementComputedStyle.top,
      left = elementComputedStyle.left,
      bottom = elementComputedStyle.bottom,
      right = elementComputedStyle.right,
      transform = elementComputedStyle.transform;
  var originComputedStyle = {
    top: top,
    left: left,
    bottom: bottom,
    right: right,
    transform: transform
  }; // If the element is currently set to display: "none", make it visible before
  // measuring the target bounding box

  if (display === "none") {
    elementStyler.set("display", target.display || "block");
  } // Apply the latest values (as set in checkAndConvertChangedValueTypes)


  elementStyler.render();
  var targetBbox = element.getBoundingClientRect();
  changedKeys.forEach(function (key) {
    // Restore styles to their **calculated computed style**, not their actual
    // originally set style. This allows us to animate between equivalent pixel units.
    var value = values.get(key);
    setAndResetVelocity(value, positionalValues[key](originBbox, originComputedStyle));
    target[key] = positionalValues[key](targetBbox, elementComputedStyle);
  });
  return target;
};

var checkAndConvertChangedValueTypes = function checkAndConvertChangedValueTypes(values, ref, target, transitionEnd) {
  if (transitionEnd === void 0) {
    transitionEnd = {};
  }

  target = __assign({}, target);
  transitionEnd = __assign({}, transitionEnd);
  var element = ref.current;
  var elementStyler = styler(element);
  var targetPositionalKeys = Object.keys(target).filter(isPositionalKey); // We want to remove any transform values that could affect the element's bounding box before
  // it's measured. We'll reapply these later.

  var removedTransformValues = [];
  var hasAttemptedToRemoveTransformValues = false;
  var changedValueTypeKeys = targetPositionalKeys.reduce(function (acc, key) {
    var value = values.get(key);
    if (!value) return acc;
    var from = value.get();
    var to = target[key];
    var fromType = getDimensionValueType(from);
    var toType; // TODO: The current implementation of this basically throws an error
    // if you try and do value conversion via keyframes. There's probably
    // a way of doing this but the performance implications would need greater scrutiny,
    // as it'd be doing multiple resize-remeasure operations.

    if (isKeyframesTarget(to)) {
      var numKeyframes = to.length;

      for (var i = to[0] === null ? 1 : 0; i < numKeyframes; i++) {
        if (!toType) {
          toType = getDimensionValueType(to[i]);
          invariant(toType === fromType || isNumOrPxType(fromType) && isNumOrPxType(toType), "Keyframes must be of the same dimension as the current value");
        } else {
          invariant(getDimensionValueType(to[i]) === toType, "All keyframes must be of the same type");
        }
      }
    } else {
      toType = getDimensionValueType(to);
    }

    if (fromType !== toType) {
      // If they're both just number or px, convert them both to numbers rather than
      // relying on resize/remeasure to convert (which is wasteful in this situation)
      if (isNumOrPxType(fromType) && isNumOrPxType(toType)) {
        var current = value.get();

        if (typeof current === "string") {
          value.set(parseFloat(current));
        }

        if (typeof to === "string") {
          target[key] = parseFloat(to);
        } else if (Array.isArray(to) && toType === px) {
          target[key] = to.map(parseFloat);
        }
      } else {
        // If we're going to do value conversion via DOM measurements, we first
        // need to remove non-positional transform values that could affect the bbox measurements.
        if (!hasAttemptedToRemoveTransformValues) {
          removedTransformValues = removeNonTranslationalTransform(values, elementStyler);
          hasAttemptedToRemoveTransformValues = true;
        }

        acc.push(key);
        transitionEnd[key] = transitionEnd[key] !== undefined ? transitionEnd[key] : target[key];
        setAndResetVelocity(value, to);
      }
    }

    return acc;
  }, []);

  if (changedValueTypeKeys.length) {
    var convertedTarget = convertChangedValueTypes(target, values, element, elementStyler, changedValueTypeKeys); // If we removed transform values, reapply them before the next render

    if (removedTransformValues.length) {
      removedTransformValues.forEach(function (_a) {
        var key = _a[0],
            value = _a[1];
        values.get(key).set(value);
      });
    } // Reapply original values


    elementStyler.render();
    return {
      target: convertedTarget,
      transitionEnd: transitionEnd
    };
  } else {
    return {
      target: target,
      transitionEnd: transitionEnd
    };
  }
};
/**
 * Convert value types for x/y/width/height/top/left/bottom/right
 *
 * Allows animation between `'auto'` -> `'100%'` or `0` -> `'calc(50% - 10vw)'`
 *
 * @param values
 * @param ref
 * @param target
 * @param transitionEnd
 * @internal
 */


function unitConversion(values, ref, target, transitionEnd) {
  return hasPositionalKey(target) ? checkAndConvertChangedValueTypes(values, ref, target, transitionEnd) : {
    target: target,
    transitionEnd: transitionEnd
  };
}

var parseDomVariant = function parseDomVariant(values, ref) {
  return function (target, transitionEnd) {
    var resolved = resolveCSSVariables(values, ref, target, transitionEnd);
    target = resolved.target;
    transitionEnd = resolved.transitionEnd;
    return unitConversion(values, ref, target, transitionEnd);
  };
};

function useForceUpdate() {
  var _a = useState(0),
      forcedRenderCount = _a[0],
      setForcedRenderCount = _a[1];

  return useCallback(function () {
    return setForcedRenderCount(forcedRenderCount + 1);
  }, [forcedRenderCount]);
}

var SyncLayoutContext = createContext(null);
/**
 * When layout changes happen asynchronously to their instigating render (ie when exiting
 * children of `AnimatePresence` are removed), `SyncLayout` can wrap parent and sibling
 * components that need to animate as a result of this layout change.
 *
 * @motion
 *
 * ```jsx
 * const MyComponent = ({ isVisible }) => {
 *   return (
 *     <SyncLayout>
 *       <AnimatePresence>
 *         {isVisible && (
 *           <motion.div exit={{ opacity: 0 }} />
 *         )}
 *       </AnimatePresence>
 *       <motion.div positionTransition />
 *     </SyncLayout>
 *   )
 * }
 * ```
 *
 * @internalremarks
 *
 * The way this component works is by memoising a function and passing it down via context.
 * The function, when called, updates the local state, which is used to invalidate the
 * memoisation cache. A new function is called, performing a synced re-render of components
 * that are using the SyncLayoutContext.
 *
 * @internal
 */

var UnstableSyncLayout = function UnstableSyncLayout(_a) {
  var children = _a.children;
  var forceUpdate = useForceUpdate();
  return createElement(SyncLayoutContext.Provider, {
    value: forceUpdate
  }, children);
};

var _a;

var StepName;

(function (StepName) {
  StepName["Prepare"] = "prepare";
  StepName["Read"] = "read";
  StepName["Render"] = "render";
})(StepName || (StepName = {}));

var stepOrder = [StepName.Prepare, StepName.Read, StepName.Render];
var jobs = stepOrder.reduce(function (acc, key) {
  acc[key] = [];
  return acc;
}, {});
var jobsNeedProcessing = false;

function flushCallbackList(list) {
  var numJobs = list.length;

  for (var i = 0; i < numJobs; i++) {
    list[i]();
  }

  list.length = 0;
}

function flushAllJobs() {
  if (!jobsNeedProcessing) return;
  flushCallbackList(jobs.prepare);
  flushCallbackList(jobs.read);
  flushCallbackList(jobs.render);
  jobsNeedProcessing = false;
} // Note: The approach of schedulng jobs during the render step is incompatible with concurrent mode
// where multiple renders might happen without a DOM update. This would result in unneccessary batched
// jobs. But this was already a problem with our previous approach to positionTransition.
// Hopefully the React team offer a getSnapshotBeforeUpdate-esque hook and we can move to that.


var createUseSyncEffect = function createUseSyncEffect(stepName) {
  return function (callback) {
    if (!callback) return;
    jobsNeedProcessing = true;
    jobs[stepName].push(callback);
  };
};

var layoutSync = (_a = {}, _a[StepName.Prepare] = createUseSyncEffect(StepName.Prepare), _a[StepName.Read] = createUseSyncEffect(StepName.Read), _a[StepName.Render] = createUseSyncEffect(StepName.Render), _a); // TODO: If we ever make this a public hook, add a check within `createUseSyncEffect` that, in development mode,
// adds a useEffect to check if there's any remaining jobs and throw an error that we must add the `useLayoutSync`
// hook to every component that schedules a job.

function useLayoutSync() {
  return useLayoutEffect(flushAllJobs);
}

function isHTMLElement(element) {
  return element instanceof HTMLElement;
}

var defaultLayoutTransition = {
  duration: 0.8,
  ease: [0.45, 0.05, 0.19, 1.0]
};
var defaultPositionTransition = underDampedSpring();

function getDefaultLayoutTransition(positionOnly) {
  return positionOnly ? defaultPositionTransition : defaultLayoutTransition;
}

function isResolver(transition) {
  return typeof transition === "function";
} // Find the center of a Layout definition. We do this to account for potential changes
// in the top/left etc that are actually just as a result of width/height changes.


function centerOf(_a) {
  var top = _a.top,
      left = _a.left,
      width = _a.width,
      height = _a.height;
  var right = left + width;
  var bottom = top + height;
  return {
    x: (left + right) / 2,
    y: (top + bottom) / 2
  };
}

function calcDelta(prev, next) {
  var prevCenter = centerOf(prev);
  var nextCenter = centerOf(next);
  return {
    x: prevCenter.x - nextCenter.x,
    y: prevCenter.y - nextCenter.y,
    width: prev.width - next.width,
    height: prev.height - next.height
  };
}

var offset = {
  getLayout: function getLayout(_a) {
    var offset = _a.offset;
    return offset;
  },
  measure: function measure(element) {
    var offsetLeft = element.offsetLeft,
        offsetTop = element.offsetTop,
        offsetWidth = element.offsetWidth,
        offsetHeight = element.offsetHeight;
    return {
      left: offsetLeft,
      top: offsetTop,
      width: offsetWidth,
      height: offsetHeight
    };
  }
};
var boundingBox = {
  getLayout: function getLayout(_a) {
    var boundingBox = _a.boundingBox;
    return boundingBox;
  },
  measure: function measure(element) {
    var _a = element.getBoundingClientRect(),
        left = _a.left,
        top = _a.top,
        width = _a.width,
        height = _a.height;

    return {
      left: left,
      top: top,
      width: width,
      height: height
    };
  }
};

function readPositionStyle(element) {
  return window.getComputedStyle(element).position;
}

function getLayoutType(prev, next, positionOnly) {
  return positionOnly && prev === next ? offset : boundingBox;
}

function isSizeKey(key) {
  return key === "width" || key === "height";
}

function useLayoutAnimation(ref, values, controls, layoutTransition, positionOnly) {
  if (positionOnly === void 0) {
    positionOnly = false;
  } // Allow any parent SyncLayoutContext components to force-update this component


  useContext(SyncLayoutContext);
  var element = ref.current;
  useLayoutSync(); // If we don't have a HTML element we can early return here. We've already called all the hooks.

  if (!isHTMLElement(element)) return; // Keep track of the position style prop. Ideally we'd compare offset as this is uneffected by
  // the same transforms that we want to use to performantly animate the layout. But if position changes,
  // for example between "static" and "fixed", we can no longer rely on the offset and need
  // to use the visual bounding box.

  var prevPosition = readPositionStyle(element);
  var prev = {
    offset: offset.measure(element),
    boundingBox: boundingBox.measure(element)
  }; // Keep track of any existing transforms so we can reapply them after measuring the target bounding box.

  var transform = "";
  var next;
  var compare; // We split the unsetting, read and reapplication of the `transform` style prop into
  // different steps via useSyncEffect. Multiple components might all be doing the same
  // thing and by splitting these jobs and flushing them in batches we prevent layout thrashing.

  layoutSync.prepare(function () {
    // Unset the transform of all layoutTransition components so we can accurately measure
    // the target bounding box
    transform = element.style.transform;
    element.style.transform = "";
  });
  layoutSync.read(function () {
    // Read the target VisualInfo of all layoutTransition components
    next = {
      offset: offset.measure(element),
      boundingBox: boundingBox.measure(element)
    };
    var nextPosition = readPositionStyle(element);
    compare = getLayoutType(prevPosition, nextPosition, positionOnly);
  });
  layoutSync.render(function () {
    // Reverse the layout delta of all newly laid-out layoutTransition components into their
    // prev visual state and then animate them into their new one using transforms.
    var prevLayout = compare.getLayout(prev);
    var nextLayout = compare.getLayout(next);
    var delta = calcDelta(prevLayout, nextLayout);
    var hasAnyChanged = delta.x || delta.y || delta.width || delta.height;

    if (!hasAnyChanged) {
      // If layout hasn't changed, reapply the transform and get out of here.
      transform && (element.style.transform = transform);
      return;
    }

    syncRenderSession.open();
    var target = {};
    var transition = {};
    var transitionDefinition = isResolver(layoutTransition) ? layoutTransition({
      delta: delta
    }) : layoutTransition;

    function makeTransition(layoutKey, transformKey, targetValue, visualOrigin) {
      // If this dimension hasn't changed, early return
      var deltaKey = isSizeKey(layoutKey) ? layoutKey : transformKey;
      if (!delta[deltaKey]) return;
      var baseTransition = typeof transitionDefinition === "boolean" ? __assign({}, getDefaultLayoutTransition(positionOnly)) : transitionDefinition;
      var value = values.get(transformKey, targetValue);
      var velocity = value.getVelocity();
      transition[transformKey] = baseTransition[transformKey] ? __assign({}, baseTransition[transformKey]) : __assign({}, baseTransition);

      if (transition[transformKey].velocity === undefined) {
        transition[transformKey].velocity = velocity || 0;
      } // The target value of all transforms is the default value of that prop (ie x = 0, scaleX = 1)
      // This is because we're inverting the layout change with `transform` and then animating to `transform: none`


      target[transformKey] = targetValue;
      var offsetToApply = !isSizeKey(layoutKey) && compare === offset ? value.get() : 0;
      value.set(visualOrigin + offsetToApply);
    }

    makeTransition("left", "x", 0, delta.x);
    makeTransition("top", "y", 0, delta.y);

    if (!positionOnly) {
      makeTransition("width", "scaleX", 1, prev.boundingBox.width / next.boundingBox.width);
      makeTransition("height", "scaleY", 1, prev.boundingBox.height / next.boundingBox.height);
    }

    target.transition = transition; // Only start the transition if `transitionDefinition` isn't `false`. Otherwise we want
    // to leave the values in their newly-inverted state and let the user cope with the rest.

    transitionDefinition && controls.start(target); // Force a render to ensure there's no visual flickering

    syncRenderSession.flush();
  });
}

var Layout = {
  key: "layout",
  shouldRender: function shouldRender(_a) {
    var positionTransition = _a.positionTransition,
        layoutTransition = _a.layoutTransition;
    invariant(!(positionTransition && layoutTransition), "Don't set both positionTransition and layoutTransition on the same component");
    return typeof window !== "undefined" && !!(positionTransition || layoutTransition);
  },
  Component: makeHookComponent(function (_a) {
    var innerRef = _a.innerRef,
        controls = _a.controls,
        values = _a.values,
        positionTransition = _a.positionTransition,
        layoutTransition = _a.layoutTransition;
    useLayoutAnimation(innerRef, values, controls, layoutTransition || positionTransition, !!positionTransition);
  })
};
/**
 * A list of all valid MotionProps
 *
 * @internalremarks
 * This doesn't throw if a `MotionProp` name is missing - it should.
 */

var validMotionProps = new Set(["initial", "animate", "exit", "style", "variants", "transition", "transformTemplate", "transformValues", "custom", "inherit", "static", "positionTransition", "layoutTransition", "onAnimationComplete", "onUpdate", "onDragStart", "onDrag", "onDragEnd", "onDirectionLock", "onDragTransitionEnd", "drag", "dragConstraints", "dragDirectionLock", "dragElastic", "dragMomentum", "dragPropagation", "dragTransition", "_dragValueX", "_dragValueY", "_dragTransitionControls", "dragOriginX", "dragOriginY", "onPan", "onPanStart", "onPanEnd", "onPanSessionStart", "onTap", "onTapStart", "onTapCancel", "whileHover", "whileTap", "onHoverEnd", "onHoverStart"]);
/**
 * Check whether a prop name is a valid `MotionProp` key.
 *
 * @param key - Name of the property to check
 * @returns `true` is key is a valid `MotionProp`.
 *
 * @public
 */

function isValidMotionProp(key) {
  return validMotionProps.has(key);
}

var AnimatePropType;

(function (AnimatePropType) {
  AnimatePropType["Target"] = "Target";
  AnimatePropType["VariantLabel"] = "VariantLabel";
  AnimatePropType["AnimationSubscription"] = "AnimationSubscription";
})(AnimatePropType || (AnimatePropType = {}));

function shallowCompare(next, prev) {
  if (prev === null) return false;
  var prevLength = prev.length;
  if (prevLength !== next.length) return false;

  for (var i = 0; i < prevLength; i++) {
    if (prev[i] !== next[i]) return false;
  }

  return true;
}

var hasUpdated = function hasUpdated(prev, next) {
  return next !== undefined && (Array.isArray(prev) && Array.isArray(next) ? !shallowCompare(next, prev) : prev !== next);
};

function targetWithoutTransition(_a, mergeTransitionEnd) {
  if (mergeTransitionEnd === void 0) {
    mergeTransitionEnd = false;
  }

  var transition = _a.transition,
      transitionEnd = _a.transitionEnd,
      target = __rest(_a, ["transition", "transitionEnd"]);

  return mergeTransitionEnd ? __assign({}, target, transitionEnd) : target;
}
/**
 * Handle the `animate` prop when its an object of values, ie:
 *
 * ```jsx
 * <motion.div animate={{ opacity: 1 }} />
 * ```
 *
 * @internalremarks
 * It might be worth consolidating this with `use-variants`
 *
 * ```jsx
 * <motion.div animate="visible" />
 * ```
 *
 * @param target
 * @param controls
 * @param values
 * @param transition
 *
 * @internal
 */


function useAnimateProp(targetAndTransition, controls, values, defaultTransition) {
  var isInitialRender = useRef(true);
  var prevValues = useRef(null);

  if (!prevValues.current) {
    prevValues.current = targetWithoutTransition(targetAndTransition, true);
  }

  useEffect(function () {
    var targetToAnimate = {}; // These are the values we're actually animating

    var animatingTarget = targetWithoutTransition(targetAndTransition); // This is the target as it'll be once transitionEnd values are applied

    var finalTarget = targetWithoutTransition(targetAndTransition, true); // Detect which values have changed between renders

    for (var key in animatingTarget) {
      // This value should animate on mount if this value doesn't already exist (wasn't
      // defined in `style` or `initial`) or if it does exist and it's already changed.
      var shouldAnimateOnMount = isInitialRender.current && (!values.has(key) || values.get(key).get() !== finalTarget[key]); // If this value has updated between renders or it's we're animating this value on mount,
      // add it to the animate target.

      var isValidValue = finalTarget[key] !== null;
      var valueHasUpdated = hasUpdated(prevValues.current[key], finalTarget[key]);

      if (isValidValue && (valueHasUpdated || shouldAnimateOnMount)) {
        targetToAnimate[key] = animatingTarget[key];
      }
    }

    isInitialRender.current = false;
    prevValues.current = __assign({}, prevValues.current, finalTarget);

    if (Object.keys(targetToAnimate).length) {
      controls.start(__assign({}, targetToAnimate, {
        transition: targetAndTransition.transition || defaultTransition,
        transitionEnd: targetAndTransition.transitionEnd
      }));
    }
  }, [targetAndTransition]);
}

var labelsToArray = function labelsToArray(label) {
  if (!label) {
    return [];
  }

  if (Array.isArray(label)) {
    return label;
  }

  return [label];
};

var resolveVariantLabels = function resolveVariantLabels(variant) {
  var unresolvedVariant = variant instanceof MotionValue ? variant.get() : variant;
  return Array.from(new Set(labelsToArray(unresolvedVariant)));
};
/**
 * Hooks in React sometimes accept a dependency array as their final argument. (ie useEffect/useMemo)
 * When values in this array change, React re-runs the dependency. However if the array
 * contains a variable number of items, React throws an error.
 */


var asDependencyList = function asDependencyList(list) {
  return [list.join(",")];
};

var hasVariantChanged = function hasVariantChanged(oldVariant, newVariant) {
  return oldVariant.join(",") !== newVariant.join(",");
};
/**
 * Handle variants and the `animate` prop when its set as variant labels.
 *
 * @param initial - Initial variant(s)
 * @param animate - Variant(s) to animate to
 * @param inherit - `true` is inheriting animations from parent
 * @param controls - Animation controls
 *
 * @internal
 */


function useVariants(initial, animate, inherit, controls) {
  var targetVariants = resolveVariantLabels(animate);
  var context = useContext(MotionContext);
  var parentAlreadyMounted = context.hasMounted && context.hasMounted.current;
  var hasMounted = useRef(false);
  useEffect(function () {
    var shouldAnimate = false;

    if (inherit) {
      // If we're inheriting variant changes and the parent has already
      // mounted when this component loads, we need to manually trigger
      // this animation.
      shouldAnimate = !!parentAlreadyMounted;
      targetVariants = resolveVariantLabels(context.animate);
    } else {
      shouldAnimate = hasMounted.current || hasVariantChanged(resolveVariantLabels(initial), targetVariants);
    }

    shouldAnimate && controls.start(targetVariants);
    hasMounted.current = true;
  }, asDependencyList(targetVariants));
}
/**
 * `useAnimationGroupSubscription` allows a component to subscribe to an
 * externally-created `AnimationControls`, created by the `useAnimation` hook.
 *
 * @param animation
 * @param controls
 *
 * @internal
 */


function useAnimationGroupSubscription(animation, controls) {
  var unsubscribe = useMemo(function () {
    return animation.subscribe(controls);
  }, [animation]);
  useEffect(function () {
    return function () {
      unsubscribe && unsubscribe();
    };
  }, [unsubscribe]);
}

var _a$1, _b;

var AnimatePropComponents = (_a$1 = {}, _a$1[AnimatePropType.Target] = makeHookComponent(function (_a) {
  var animate = _a.animate,
      controls = _a.controls,
      values = _a.values,
      transition = _a.transition;
  return useAnimateProp(animate, controls, values, transition);
}), _a$1[AnimatePropType.VariantLabel] = makeHookComponent(function (_a) {
  var animate = _a.animate,
      _b = _a.inherit,
      inherit = _b === void 0 ? true : _b,
      controls = _a.controls,
      initial = _a.initial;
  return useVariants(initial, animate, inherit, controls);
}), _a$1[AnimatePropType.AnimationSubscription] = makeHookComponent(function (_a) {
  var animate = _a.animate,
      controls = _a.controls;
  return useAnimationGroupSubscription(animate, controls);
}), _a$1);

var isVariantLabel$1 = function isVariantLabel$1(prop) {
  return Array.isArray(prop) || typeof prop === "string";
};

var isAnimationSubscription = function isAnimationSubscription(_a) {
  var animate = _a.animate;
  return animate instanceof AnimationControls;
};

var animationProps = ["initial", "animate", "whileTap", "whileHover"];
var animatePropTypeTests = (_b = {}, _b[AnimatePropType.Target] = function (props) {
  return props.animate !== undefined && !isVariantLabel$1(props.animate) && !isAnimationSubscription(props);
}, _b[AnimatePropType.VariantLabel] = function (props) {
  return props.variants !== undefined || animationProps.some(function (key) {
    return typeof props[key] === "string";
  });
}, _b[AnimatePropType.AnimationSubscription] = isAnimationSubscription, _b);

var getAnimationComponent = function getAnimationComponent(props) {
  var animatePropType = undefined;

  for (var key in AnimatePropType) {
    if (animatePropTypeTests[key](props)) {
      animatePropType = key;
    }
  }

  return animatePropType ? AnimatePropComponents[animatePropType] : undefined;
};

var isPropValid = function isPropValid(key) {
  return !isValidMotionProp(key);
};
/**
 * Emotion and Styled Components both allow users to pass through arbitrary props to their components
 * to dynamically generate CSS. They both use the `@emotion/is-prop-valid` package to determine which
 * of these should be passed to the underlying DOM node.
 *
 * However, when styling a Motion component `styled(motion.div)`, both packages pass through *all* props
 * as it's seen as an arbitrary component rather than a DOM node. Motion only allows arbitrary props
 * passed through the `custom` prop so it doesn't *need* the payload or computational overhead of
 * `@emotion/is-prop-valid`, however to fix this problem we need to use it.
 *
 * By making it an optionalDependency we can offer this functionality only in the situations where it's
 * actually required.
 */


try {
  var emotionIsPropValid_1 = require("@emotion/is-prop-valid")["default"];

  isPropValid = function isPropValid(key) {
    // Handle events explicitly as Emotion validates them all as true
    if (key.startsWith("on")) {
      return !isValidMotionProp(key);
    } else {
      return emotionIsPropValid_1(key);
    }
  };
} catch (_a) {// We don't need to actually do anything here - the fallback is the existing `isPropValid`.
}

function filterValidProps(props) {
  var domProps = {};

  for (var key in props) {
    if (isPropValid(key)) {
      domProps[key] = props[key];
    }
  }

  return domProps;
}

var buildSVGProps = function buildSVGProps(values, style) {
  var motionValueStyles = resolveCurrent(values);
  var props = buildSVGAttrs(motionValueStyles, undefined, undefined, undefined, undefined, false);
  props.style = __assign({}, style, props.style);
  return props;
};

var functionalityComponents = [Layout, Drag, Gestures];
var numFunctionalityComponents = functionalityComponents.length;
/**
 * Create a configuration for `motion` components that provides DOM-specific functionality.
 *
 * @internal
 */

function createDomMotionConfig(Component) {
  var isDOM = typeof Component === "string";
  var isSVG = isDOM && svgElements.indexOf(Component) !== -1;
  return {
    renderComponent: function renderComponent(ref, style, values, props, isStatic) {
      var forwardedProps = isDOM ? filterValidProps(props) : props;
      var staticVisualStyles = isSVG ? buildSVGProps(values, style) : {
        style: buildStyleAttr(values, style, isStatic)
      };
      return createElement(Component, __assign({}, forwardedProps, {
        ref: ref
      }, staticVisualStyles));
    },

    /**
     * loadFunctionalityComponents gets used by the `motion` component
     *
     * Each functionality component gets provided the `ref`, animation controls and the `MotionValuesMap`
     * generated for that component, as well as all the `props` passed to it by the user.
     *
     * The pattern used to determine whether to load and use each piece of functionality is
     * consistent (should render? Then push component) and could be used to extend functionality.
     *
     * By exposing a mutable piece of memory via an API like `extendMotionComponent` we could
     * allow users to add `FunctionalComponentDefinition`s. This would allow us to offer file size
     * reductions by shipping an entry point that doesn't load gesture and drag functionality, and
     * also offer a way for users to develop plugins/other functionality. Because these functionalities
     * are loaded as components, we can look into using Suspense for this purpose.
     *
     * For user-defined functionality we'd need to allow
     *  1) User-defined prop typing (extending `P`)
     *  2) User-defined "clean props" function that removes their plugin's props before being passed to the DOM.
     */
    loadFunctionalityComponents: function loadFunctionalityComponents(ref, values, props, controls, inherit) {
      var activeComponents = []; // TODO: Consolidate Animation functionality loading strategy with other functionality components

      var Animation = getAnimationComponent(props);

      if (Animation) {
        activeComponents.push(createElement(Animation, {
          key: "animation",
          initial: props.initial,
          animate: props.animate,
          variants: props.variants,
          transition: props.transition,
          controls: controls,
          inherit: inherit,
          values: values
        }));
      }

      for (var i = 0; i < numFunctionalityComponents; i++) {
        var _a = functionalityComponents[i],
            shouldRender = _a.shouldRender,
            key = _a.key,
            Component_1 = _a.Component;

        if (shouldRender(props)) {
          activeComponents.push(createElement(Component_1, __assign({
            key: key
          }, props, {
            values: values,
            controls: controls,
            innerRef: ref
          })));
        }
      }

      return activeComponents;
    },
    getValueControlsConfig: function getValueControlsConfig(ref, values) {
      return {
        values: values,
        readValueFromSource: function readValueFromSource(key) {
          return styler(ref.current).get(key);
        },
        // TODO: This is a good second source of plugins. This function contains the CSS variable
        // and unit conversion support. These functions share a common signature. We could make another
        // API for adding these.
        makeTargetAnimatable: parseDomVariant(values, ref)
      };
    }
  };
}

var htmlMotionComponents = htmlElements.reduce(function (acc, Component) {
  var config = createDomMotionConfig(Component); // Suppress "Expression produces a union type that is too complex to represent" error
  // @ts-ignore

  acc[Component] = createMotionComponent(config);
  return acc;
}, {});
var svgMotionComponents = svgElements.reduce(function (acc, Component) {
  // Suppress "Expression produces a union type that is too complex to represent" error
  // @ts-ignore
  acc[Component] = createMotionComponent(createDomMotionConfig(Component));
  return acc;
}, {});
/**
 * HTML & SVG components, optimised for use with gestures and animation. These can be used as
 * drop-in replacements for any HTML & SVG component, all CSS & SVG properties are supported.
 *
 * @internalremarks
 *
 * I'd like to make it possible for these to be loaded "on demand" - to reduce bundle size by only
 * including HTML/SVG stylers, animation and/or gesture support when necessary.
 *
 * ```jsx
 * <motion.div animate={{ x: 100 }} />
 *
 * <motion.p animate={{ height: 200 }} />
 *
 * <svg><motion.circle r={10} animate={{ r: 20 }} /></svg>
 * ```
 *
 * @public
 */

var motion = __assign({
  custom: function custom(Component) {
    return createMotionComponent(createDomMotionConfig(Component));
  }
}, htmlMotionComponents, svgMotionComponents);
/**
 * If the provided value is a MotionValue, this returns the actual value, otherwise just the value itself
 *
 * TODO: Remove and move to library
 *
 * @internal
 */


function unwrapMotionValue(value) {
  var unwrappedValue = value instanceof MotionValue ? value.get() : value;
  return isCustomValue(unwrappedValue) ? unwrappedValue.toValue() : unwrappedValue;
}

var isCustomValueType = function isCustomValueType(v) {
  return typeof v === "object" && v.mix;
};

var getMixer = function getMixer(v) {
  return isCustomValueType(v) ? v.mix : undefined;
};

function transform() {
  var args = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }

  var useImmediate = !Array.isArray(args[0]);
  var argOffset = useImmediate ? 0 : -1;
  var inputValue = args[0 + argOffset];
  var inputRange = args[1 + argOffset];
  var outputRange = args[2 + argOffset];
  var options = args[3 + argOffset];
  var interpolator = interpolate(inputRange, outputRange, __assign({
    mixer: getMixer(outputRange[0])
  }, options));
  return useImmediate ? interpolator(inputValue) : interpolator;
}

var isTransformer = function isTransformer(v) {
  return typeof v === "function";
};

var noop = function noop(v) {
  return v;
};

function useTransform(parent, customTransform, to, options) {
  var value = useRef(null);
  var comparitor = [parent];
  var transformer = noop;

  if (isTransformer(customTransform)) {
    transformer = customTransform;
  } else if (Array.isArray(to)) {
    var from = customTransform;
    transformer = transform(from, to, options);
    comparitor = [parent, from.join(","), to.join(",")];
  }

  return useMemo(function () {
    if (value.current) value.current.destroy();
    value.current = parent.addChild({
      transformer: transformer
    });
    return value.current;
  }, comparitor);
} // Keep things reasonable and avoid scale: Infinity. In practise we might need
// to add another value, opacity, that could interpolate scaleX/Y [0,0.01] => [0,1]
// to simply hide content at unreasonable scales.


var maxScale = 100000;

var invertScale = function invertScale(scale) {
  return scale > 0.001 ? 1 / scale : maxScale;
};
/**
 * Returns a `MotionValue` each for `scaleX` and `scaleY` that update with the inverse
 * of their respective parent scales.
 *
 * This is useful for undoing the distortion of content when scaling a parent component.
 *
 * By default, `useInvertedScale` will automatically fetch `scaleX` and `scaleY` from the nearest parent.
 * By passing other `MotionValue`s in as `useInvertedScale({ scaleX, scaleY })`, it will invert the output
 * of those instead.
 *
 * @motion
 *
 * ```jsx
 * const MyComponent = () => {
 *   const { scaleX, scaleY } = useInvertedScale()
 *   return <motion.div style={{ scaleX, scaleY }} />
 * }
 * ```
 *
 * @library
 *
 * ```jsx
 * function MyComponent() {
 *   const { scaleX, scaleY } = useInvertedScale()
 *   return <Frame scaleX={scaleX} scaleY={scaleY} />
 * }
 * ```
 *
 * @public
 */


function useInvertedScale(scale) {
  var parentScaleX = useMotionValue(1);
  var parentScaleY = useMotionValue(1);
  var values = useContext(MotionContext).values;
  invariant(!!(scale || values), "If no scale values are provided, useInvertedScale must be used within a child of another motion component.");

  if (scale) {
    parentScaleX = scale.scaleX || parentScaleX;
    parentScaleY = scale.scaleY || parentScaleY;
  } else if (values) {
    parentScaleX = values.get("scaleX", 1);
    parentScaleY = values.get("scaleY", 1);
  }

  var scaleX = useTransform(parentScaleX, invertScale);
  var scaleY = useTransform(parentScaleY, invertScale);
  return {
    scaleX: scaleX,
    scaleY: scaleY
  };
}

function useOnChange(value, callback) {
  useEffect(function () {
    return isMotionValue(value) ? value.onChange(callback) : undefined;
  }, [value]);
}
/**
 * Creates a `MotionValue` that, when `set`, will use a spring animation to animate to its new state.
 *
 * It can either work as a stand-alone `MotionValue` by initialising it with a value, or as a subscriber
 * to another `MotionValue`.
 *
 * @remarks
 *
 * ```jsx
 * const x = useSpring(0, { stiffness: 300 })
 * const y = useSpring(x, { damping: 10 })
 * ```
 *
 * @param inputValue - `MotionValue` or number. If provided a `MotionValue`, when the input `MotionValue` changes, the created `MotionValue` will spring towards that value.
 * @param springConfig - Configuration options for the spring.
 * @returns `MotionValue`
 *
 * @public
 */


function useSpring(source, config) {
  if (config === void 0) {
    config = {};
  }

  var activeSpringAnimation = useRef(null);
  var value = useMotionValue(isMotionValue(source) ? source.get() : source);
  useMemo(function () {
    return value.attach(function (v, set) {
      if (activeSpringAnimation.current) {
        activeSpringAnimation.current.stop();
      }

      activeSpringAnimation.current = spring(__assign({
        from: value.get(),
        to: v,
        velocity: value.getVelocity()
      }, config)).start(set);
      return value.get();
    });
  }, Object.values(config));
  useOnChange(source, function (v) {
    return value.set(parseFloat(v));
  });
  return value;
}

var scrollX = motionValue(0);
var scrollY = motionValue(0);
var scrollXProgress = motionValue(0);
var scrollYProgress = motionValue(0);

var setProgress = function setProgress(offset, maxOffset, value) {
  value.set(!maxOffset || !offset ? 0 : offset / maxOffset);
};

var hasEventListener = false;

var addScrollListener = function addScrollListener() {
  hasEventListener = true;
  if (typeof window === "undefined") return;

  var updateScrollValues = function updateScrollValues() {
    var xOffset = window.pageXOffset;
    var yOffset = window.pageYOffset; // Set absolute positions

    scrollX.set(xOffset);
    scrollY.set(yOffset); // Set 0-1 progress

    setProgress(xOffset, document.body.clientWidth - window.innerWidth, scrollXProgress);
    setProgress(yOffset, document.body.clientHeight - window.innerHeight, scrollYProgress);
  };

  updateScrollValues();
  window.addEventListener("resize", updateScrollValues);
  window.addEventListener("scroll", updateScrollValues, {
    passive: true
  });
};

var viewportMotionValues = {
  scrollX: scrollX,
  scrollY: scrollY,
  scrollXProgress: scrollXProgress,
  scrollYProgress: scrollYProgress
};
/**
 * Provides `MotionValue`s that update when the viewport scrolls:
 *
 * - `scrollX` — Horizontal scroll distance in pixels.
 * - `scrollY` — Vertical scroll distance in pixels.
 * - `scrollXProgress` — Horizontal scroll progress between `0` and `1`.
 * - `scrollYProgress` — Vertical scroll progress between `0` and `1`.
 *
 * **Note:** If the returned scroll `MotionValue`s don't seem to be updating,
 * double check if the `body` tag styles are set to `width: 100%; height: 100%` or
 * similar, as this can break accurate measurement of viewport scroll.
 *
 * @library
 *
 * ```jsx
 * import * as React from "react"
 * import {
 *   Frame,
 *   useViewportScroll,
 *   useTransform
 * } from "framer"
 *
 * export function MyComponent() {
 *   const { scrollYProgress } = useViewportScroll()
 *   return <Frame scaleX={scrollYProgress} />
 * }
 * ```
 *
 * @motion
 *
 * ```jsx
 * export const MyComponent = () => {
 *   const { scrollYProgress } = useViewportScroll()
 *   return <motion.div style={{ scaleX: scrollYProgress }} />
 * }
 * ```
 *
 * @internalremarks
 * This isn't technically a hook yet, but in the future it might be nice
 * to accept refs to elements and add scroll listeners to those, which
 * may involve the use of lifecycle.
 *
 * @public
 */

function useViewportScroll() {
  if (!hasEventListener) {
    addScrollListener();
  }

  return viewportMotionValues;
}
/**
 * Creates `AnimationControls`, which can be used to manually start, stop
 * and sequence animations on one or more components.
 *
 * The returned `AnimationControls` should be passed to the `animate` property
 * of the components you want to animate.
 *
 * These components can then be animated with the `start` method.
 *
 * @library
 *
 * ```jsx
 * import * as React from 'react'
 * import { Frame, useAnimation } from 'framer'
 *
 * export function MyComponent(props) {
 *    const controls = useAnimation()
 *
 *    controls.start({
 *        x: 100,
 *        transition: { duration: 0.5 },
 *    })
 *
 *    return <Frame animate={controls} />
 * }
 * ```
 *
 * @motion
 *
 * ```jsx
 * import * as React from 'react'
 * import { motion, useAnimation } from 'framer-motion'
 *
 * export function MyComponent(props) {
 *    const controls = useAnimation()
 *
 *    controls.start({
 *        x: 100,
 *        transition: { duration: 0.5 },
 *    })
 *
 *    return <motion.div animate={controls} />
 * }
 * ```
 *
 * @returns Animation controller with `start` and `stop` methods
 *
 * @public
 */


function useAnimation() {
  var animationControls = useConstant(function () {
    return new AnimationControls();
  });
  useEffect(function () {
    animationControls.mount();
    return function () {
      return animationControls.unmount();
    };
  }, []);
  return animationControls;
}
/**
 * Experimental API.
 *
 * Makes an animated version of `useState`.
 *
 * @remarks
 *
 * When the returned state setter is called, values will be animated to their new target.
 *
 * This allows the animation of arbitrary React components.
 *
 * **Note:** When animating DOM components, it's always preferable to use the `animate` prop, as Framer
 * will bypass React's rendering cycle with one optimised for 60fps motion. This Hook is specifically
 * for animating props on arbitrary React components, or for animating text content.
 *
 * ```jsx
 * const [state, setState] = useAnimatedState({ percentage: 0 })
 *
 * return (
 *   <Graph
 *     percentage={state.percentage}
 *     onTap={() => setState({ percentage: 50 })}
 *   />
 * )
 * ```
 *
 * @internalremarks
 *
 * TODO:
 * - Make hook accept a typed version of Target that accepts any value (not just DOM values)
 * - Allow hook to accept single values. ie useAnimatedState(0)
 * - Allow providing MotionValues via initialState.
 *
 * @beta
 */


function useAnimatedState(initialState) {
  var _a = useState(initialState),
      animationState = _a[0],
      onUpdate = _a[1];

  var config = useConstant(function () {
    return {
      onUpdate: onUpdate
    };
  });
  var values = useMotionValues(config);
  var controls = useValueAnimationControls({
    values: values,
    readValueFromSource: function readValueFromSource(key) {
      return animationState[key];
    }
  }, {}, false);
  var startAnimation = useConstant(function () {
    return function (animationDefinition) {
      return controls.start(animationDefinition);
    };
  });
  useEffect(function () {
    values.mount();
    return function () {
      return values.unmount();
    };
  }, []);
  return [animationState, startAnimation];
}
/**
 * Cycles through a series of visual properties. Can be used to toggle between or cycle through animations. It works similar to `useState` in React. It is provided an initial array of possible states, and returns an array of two arguments.
 *
 * @library
 *
 * ```jsx
 * import * as React from "react"
 * import { Frame, useCycle } from "framer"
 *
 * export function MyComponent() {
 *   const [x, cycleX] = useCycle(0, 50, 100)
 *
 *   return (
 *     <Frame
 *       animate={{ x: x }}
 *       onTap={() => cycleX()}
 *      />
 *    )
 * }
 * ```
 *
 * @motion
 *
 * An index value can be passed to the returned `cycle` function to cycle to a specific index.
 *
 * ```jsx
 * import * as React from "react"
 * import { motion, useCycle } from "framer-motion"
 *
 * export const MyComponent = () => {
 *   const [x, cycleX] = useCycle(0, 50, 100)
 *
 *   return (
 *     <motion.div
 *       animate={{ x: x }}
 *       onTap={() => cycleX()}
 *      />
 *    )
 * }
 * ```
 *
 * @param items - items to cycle through
 * @returns [currentState, cycleState]
 *
 * @public
 */


function useCycle() {
  var items = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    items[_i] = arguments[_i];
  } // TODO: After Framer X beta, remove this warning


  warning(items.length > 1, "useCycle syntax has changed. `useCycle([0, 1, 2])` becomes `useCycle(0, 1, 2)`");
  var index = useRef(0);

  var _a = useState(items[index.current]),
      item = _a[0],
      setItem = _a[1];

  return [item, function (next) {
    index.current = typeof next !== "number" ? wrap(0, items.length, index.current + 1) : next;
    setItem(items[index.current]);
  }];
}

var PresenceChild = function PresenceChild(_a) {
  var children = _a.children,
      exitProps = _a.exitProps;
  var context = useContext(MotionContext); // Create a new `value` in all instances to ensure `motion` children re-render
  // and detect any layout changes that might have occurred.

  context = exitProps ? __assign({}, context, {
    exitProps: exitProps
  }) : __assign({}, context);
  return createElement(MotionContext.Provider, {
    value: context
  }, children);
};

function getChildKey(child) {
  return child.key || "";
}

function updateChildLookup(children, allChildren) {
  var seenChildren = process.env.NODE_ENV !== "production" ? new Set() : null;
  children.forEach(function (child) {
    var key = getChildKey(child);

    if (process.env.NODE_ENV !== "production" && seenChildren) {
      if (seenChildren.has(key)) {
        console.warn("Children of AnimatePresence require unique keys. \"" + key + "\" is a duplicate.");
      }

      seenChildren.add(key);
    }

    allChildren.set(key, child);
  });
}

function onlyElements(children) {
  var filtered = []; // We use forEach here instead of map as map mutates the component key by preprending `.$`

  Children.forEach(children, function (child) {
    if (isValidElement(child)) filtered.push(child);
  });
  return filtered;
}
/**
 * The `AnimatePresence` component enables the use of the `exit` prop to animate components
 * when they're removed from the component tree.
 *
 * When adding/removing more than a single child component, every component
 * **must** be given a unique `key` prop.
 *
 * You can propagate exit animations throughout a tree by using variants.
 *
 * @library
 *
 * You can use any component(s) within `AnimatePresence`, but the first `Frame` in each should
 * have an `exit` property defined.
 *
 * ```jsx
 * import { Frame, AnimatePresence } from 'framer'
 *
 * // As items are added and removed from `items`
 * export function Items({ items }) {
 *   return (
 *     <AnimatePresence>
 *       {items.map(item => (
 *         <Frame
 *           key={item.id}
 *           initial={{ opacity: 0 }}
 *           animate={{ opacity: 1 }}
 *           exit={{ opacity: 0 }}
 *         />
 *       ))}
 *     </AnimatePresence>
 *   )
 * }
 * ```
 *
 * @motion
 *
 * You can use any component(s) within `AnimatePresence`, but the first `motion` component in each should
 * have an `exit` property defined.
 *
 * ```jsx
 * import { motion, AnimatePresence } from 'framer-motion'
 *
 * export const Items = ({ items }) => (
 *   <AnimatePresence>
 *     {items.map(item => (
 *       <motion.div
 *         key={item.id}
 *         initial={{ opacity: 0 }}
 *         animate={{ opacity: 1 }}
 *         exit={{ opacity: 0 }}
 *       />
 *     ))}
 *   </AnimatePresence>
 * )
 * ```
 *
 * @public
 */


var AnimatePresence = function AnimatePresence(_a) {
  var children = _a.children,
      custom = _a.custom,
      _b = _a.initial,
      initial = _b === void 0 ? true : _b,
      onExitComplete = _a.onExitComplete,
      exitBeforeEnter = _a.exitBeforeEnter; // We want to force a re-render once all exiting animations have finished. We
  // either use a local forceUpdate function, or one from a parent context if it exists.

  var localForceUpdate = useForceUpdate();
  var contextForceUpdate = useContext(SyncLayoutContext);
  var forceUpdate = contextForceUpdate || localForceUpdate;
  var isInitialRender = useRef(true); // Filter out any children that aren't ReactElements. We can only track ReactElements with a props.key

  var filteredChildren = onlyElements(children); // Keep a living record of the children we're actually rendering so we
  // can diff to figure out which are entering and exiting

  var presentChildren = useRef(filteredChildren); // A lookup table to quickly reference components by key

  var allChildren = useRef(new Map()).current; // A living record of all currently exiting components.

  var exiting = useRef(new Set()).current;
  updateChildLookup(filteredChildren, allChildren); // If this is the initial component render, just deal with logic surrounding whether
  // we play onMount animations or not.

  if (isInitialRender.current) {
    isInitialRender.current = false;
    return createElement(Fragment, null, filteredChildren.map(function (child) {
      return createElement(PresenceChild, {
        key: getChildKey(child),
        exitProps: initial ? undefined : {
          initial: false
        }
      }, child);
    }));
  } // If this is a subsequent render, deal with entering and exiting children


  var childrenToRender = filteredChildren.slice(); // Diff the keys of the currently-present and target children to update our
  // exiting list.

  var presentKeys = presentChildren.current.map(getChildKey);
  var targetKeys = filteredChildren.map(getChildKey); // Diff the present children with our target children and mark those that are exiting

  var numPresent = presentKeys.length;

  for (var i = 0; i < numPresent; i++) {
    var key = presentKeys[i];

    if (targetKeys.indexOf(key) === -1) {
      exiting.add(key);
    } else {
      // In case this key has re-entered, remove from the exiting list
      exiting["delete"](key);
    }
  } // If we currently have exiting children, and we're deferring rendering incoming children
  // until after all current children have exiting, empty the childrenToRender array


  if (exitBeforeEnter && exiting.size) {
    childrenToRender = [];
  } // Loop through all currently exiting components and clone them to overwrite `animate`
  // with any `exit` prop they might have defined.


  exiting.forEach(function (key) {
    // If this component is actually entering again, early return
    if (targetKeys.indexOf(key) !== -1) return;
    var child = allChildren.get(key);
    if (!child) return;
    var insertionIndex = presentKeys.indexOf(key);

    var onExit = function onExit() {
      exiting["delete"](key); // Remove this child from the present children

      var removeIndex = presentChildren.current.findIndex(function (child) {
        return child.key === key;
      });
      presentChildren.current.splice(removeIndex, 1); // Defer re-rendering until all exiting children have indeed left

      if (!exiting.size) {
        presentChildren.current = filteredChildren;
        forceUpdate();
        onExitComplete && onExitComplete();
      }
    };

    var exitProps = {
      custom: custom,
      isExiting: true,
      onExitComplete: onExit
    };
    childrenToRender.splice(insertionIndex, 0, createElement(PresenceChild, {
      key: getChildKey(child),
      exitProps: exitProps
    }, child));
  }); // Add `MotionContext` even to children that don't need it to ensure we're rendering
  // the same tree between renders

  childrenToRender = childrenToRender.map(function (child) {
    var key = child.key;
    return exiting.has(key) ? child : createElement(PresenceChild, {
      key: getChildKey(child)
    }, child);
  });
  presentChildren.current = childrenToRender;

  if (process.env.NODE_ENV !== "production" && exitBeforeEnter && childrenToRender.length > 1) {
    console.warn("You're attempting to animate multiple children within AnimatePresence, but its exitBeforeEnter prop is set to true. This will lead to odd visual behaviour.");
  }

  return createElement(Fragment, null, exiting.size ? childrenToRender : childrenToRender.map(function (child) {
    return cloneElement(child);
  }));
};

export { AnimatePresence, AnimationControls, MotionContext, MotionPluginContext, MotionPlugins, MotionValue, Point, UnstableSyncLayout, animationControls, createMotionComponent, isValidMotionProp, motion, motionValue, transform, unwrapMotionValue, useAnimatedState, useAnimation, useCycle, useDomEvent, useExternalRef, useGestures, useInvertedScale, useMotionValue, usePanGesture, useSpring, useTapGesture, useTransform, useViewportScroll };