// @ts-check

import { createElement, Fragment } from 'react'
import { useDeck } from "mdx-deck";
import { useEffect } from "react";

export function useNotes(notesElements) {
  const context = useDeck()

  useEffect(() => {

    console.log({ context })

    if (!context || !context.register) {
      return
    }

    if (typeof context.index === "undefined") {
      return
    }

    const notes = getNotesFromElements(notesElements)
    context.register(context.index, 'notes', notes)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

function getNotesFromElements(notesElements) {
  const notesMap = notesElements.reduce((result, item) => {
    const [element, index] = item

    if (!element) {
      // this is a step with empty notes
      result[index] = result[index] || null

      return result;
    }

    const { props } = element;

    let current = null

    if (result[index] === null) {
      // Don't do anything
    } else if (typeof result[index] === 'string') {
      current = result[index]
    } else if (typeof result[index] === 'object' && result[index].inline) {
      current = result[index].text
    }

    if (props && props.inline) {
      // this is <Notes inline={true} />

      const text = [props.children, current].filter(Boolean).join("\n")

      result[index] = {
        inline: true,
        text: `${index}: ${text}`
      };

      return result
    }

    // this is <Notes>something</Notes>
    // we shouldn't return an object here,
    // to be compatible with the default Presenter
    const text = [props && props.children, current].filter(Boolean).join("\n")
    result[index] = text ? `${index}: ${text}` : null
    return result
  }, {})

  const steps = Math.max(0, ...Object.keys(notesMap).map(Number))
  const notes = new Array(steps)

  for (let i = 0; i < steps; i++) {
    notes[i] = notesMap[Number(i)] || null
    if (notes[i]) {
      notes[i] = createElement(Fragment, undefined, [
        `${notes[i]}`,
        createElement('br')
      ])
    }
  }

  return notes;
}

export function getTextFromNotes(notes) {
  if (notes === null) {
    // this is a step with empty notes
    // we don't add extra lines here
    // to allow a line of text with multiple notes
    return "";
  }

  if (typeof notes === "object") {
    // this comes from a step with inline=true
    // but we check again just in case
    return notes.text + (notes.inline ? "" : "\n");
  } else {
    // this could be an empty note from any slide
    // or a note from a step without the inline prop
    return notes + "\n";
  }
}
