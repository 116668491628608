import React, { useCallback } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useSteps, useDeck } from 'gatsby-theme-mdx-deck'
import { next } from 'gatsby-theme-mdx-deck/src/navigate'

export function AnimatedLayout({ children, count = 0 }) {
  const context = useDeck()
  const step = useSteps(count + 1)

  const onExited = useCallback(() => {
    next(context)
  }, [context])

  return (
    <AnimatePresence onExitComplete={onExited}>
      { step <= count && (<motion.div
        key="content"
        transition={{ duration: .5, delay: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>) }
    </AnimatePresence>
  )
}

export { OneRaamLayout, RaamLayout, RaamExampleLayout } from './RaamLayout'