module.exports = [{
  plugin: require('C:/Users/Derk-Jan/Documents/Github/mdx-raam/deck/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [],
    "gatsbyRemarkPlugins": ["gatsby-remark-import-code"],
    "remarkPlugins": [null, null]
  }
}, {
  plugin: require('C:/Users/Derk-Jan/Documents/Github/mdx-raam/deck/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('C:/Users/Derk-Jan/Documents/Github/mdx-raam/deck/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('C:/Users/Derk-Jan/Documents/Github/mdx-raam/deck/node_modules/gatsby-theme-mdx-deck/gatsby-browser.js'),
  options: {
    "plugins": [],
    "cli": true,
    "contentPath": "C:\\Users\\Derk-Jan\\Documents\\Github\\mdx-raam\\deck\\deck.mdx"
  }
}];